import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Breadcrumb, Button, Modal, message, Select } from 'antd';
import { connect } from "react-redux";
import "./Main.less"
import logo from "../../resource/images/logo.png";
import InnerRouters from "./InnerRouters";
import { setBreadcrumb, getConfigAsync, initLeftMenuAction } from "../../store/actionCreator/global";

import { clearToken } from "../../store/actionCreator/global";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import httpClient from '../../request/index'
import { compose } from "redux";
const { Option } = Select;

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;
const { confirm } = Modal;




const MENU_DATA = [
  {
    id: "1",
    title: "工具中心",
    icon: "",
    path: "",
    children: [
      {
        id: "1-1",
        title: "项目管理",
        icon: "",
        path: "/Main/LiveCodeSetting",

      },
      {

        id: "1-2",
        title: "微信活码",
        icon: "",
        path: "/Main/liverCodeCard",

      }
    ]
  },
  {
    id: "2",
    title: "召回管理",
    icon: "",
    path: "",
    children: [
      {
        id: "2-1",
        title: "项目管理",
        icon: "",
        path: "/Main/recall/manages",

      },
      {
        id:'2-2',
        title:'行为表单列表',
        icon:"",
        path:"/Main/recall/behaviour"
      },
      {

        id: "2-3",
        title: "统计页",
        icon: "",
        path: "/Main/recall/statistics",

      },
      {

        id: "2-4",
        title: "短信模板",
        icon: "",
        path: "/Main/recall/notetemplate",

      }, {

        id: "2-5",
        title: "短信任务",
        icon: "",
        path: "/Main/recall/noteTask",

      }, {

        id: "2-6",
        title: "任务数据页",
        icon: "",
        path: "/Main/recall/noteData",

      }
    ]
  }
];
const BREADCRUMB_MAP_DATA = {
  "/Main/BannerList": "banner",
  "/Main/UserList": "user",
  "/Main/Feedback": "feedback",
  "/Main/VideoList": "video",
  "/Main/English": "english",
  "/Main/Politics": "politics",
  "/Main/Course": "course"
};

class Main extends Component {

  constructor(props) {
    super(props);
    this.rootSubmenuKeys = MENU_DATA.map(item => item.id);
    this.state = {
      collapsed: false,
      openKeys: [this.rootSubmenuKeys[0]],
      activeMenu: [],
      showMenu: false,
      isMenuALL: [
        {
          value: 1,
          name: 'a'
        }
      ],//这是代表的是所有的项目
    };
  }

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  handleQuit = () => {
    let that = this;
    confirm({
      title: '提示',
      icon: <ExclamationCircleOutlined />,
      content: '确定退出吗？',
      onOk() {
        that.props.clearToken();
        that.props.history.replace("/")
      },
      onCancel() {
        message.warning("取消")
      },
    });

  };

  getInitMenuList = async () => {
    try {
      let menuList = httpClient.request({
        url: "",
        type: 'post',
        data: {

        }
      })
      //处理判断逻辑是否返回成功
      this.setState({
        isMenuALL: [menuList]
      })

    } catch (error) {

    }
  }
  handleChange = () => {
    this.props.initLeftMenuAction();
  }

  componentDidMount() {
    //在这里准备请求所有的项目分类
    let isAuthor = localStorage.getItem('isAuthor');
    if (!Number(isAuthor)) {
      MENU_DATA[0].children = MENU_DATA[0].children.filter(v => v.path != '/Main/LiveCodeSetting')
    }
    if (!this.props.token) {
      return this.props.history.replace("/")
    }
    // this.props.getConfig();
    let path = this.props.location.pathname;
    MENU_DATA.forEach(menu => {
      menu.children && menu.children.forEach(item => {
        if (item.path === path) {
          this.setState({
            activeMenu: [item.id],
            openKeys: [menu.id],
            showMenu: true
          })
        } else {
          this.setState({
            showMenu: true
          })
        }
      })
    });

    for (let prop in BREADCRUMB_MAP_DATA) {
      if (BREADCRUMB_MAP_DATA.hasOwnProperty(prop)) {
        if (path.indexOf(prop) > -1) {
          this.props.setBreadcrumb(BREADCRUMB_MAP_DATA[prop])
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.location !== this.props.location) {
      let path = this.props.location.pathname;
      for (let prop in BREADCRUMB_MAP_DATA) {
        if (BREADCRUMB_MAP_DATA.hasOwnProperty(prop)) {
          if (path.indexOf(prop) > -1) {
            this.props.setBreadcrumb(BREADCRUMB_MAP_DATA[prop])
          }
        }
      }
    }
  }

  render() {
    const { isMenuALL } = this.state;
    const { handleChange } = this;
    const SelectOptions = isMenuALL.map((v, i) => {
      return (<Option value={v.value} key={i}>{v.name}</Option>)
    })
    return (
      <div className="main-page">
        <Layout style={{ minHeight: '100vh' }}>
          <Sider collapsible

            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse.bind(this)}>
            {
              !this.state.collapsed ? <div className="logo">
                <img src={logo} alt="" />
              </div> : null
            }
            {
              this.state.showMenu ? <Menu className="side-menu"
                openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange.bind(this)}
                theme="dark"
                defaultSelectedKeys={this.state.activeMenu}
                mode="inline">
                {
                  MENU_DATA.map((subMenu, index) => {
                    return (
                      <SubMenu key={subMenu.id} icon={subMenu.icon} title={subMenu.title}>
                        {
                          subMenu.children.map((menu, menuIndex) => {
                            return (
                              <Menu.Item key={menu.id}><Link to={menu.path}>{menu.title}</Link></Menu.Item>
                            )
                          })
                        }
                      </SubMenu>
                    )
                  })
                }
              </Menu> : null
            }
          </Sider>
          <Layout className="site-layout">
            <Header className="site-layout-background" style={{ padding: 0 }}>
              <div className="site-header-actions">\
                <div>
                  <img
                    width={200}
                    style={{
                      borderRadius: '50%',
                      width: '45px',
                      height: '45px',
                      marginRight: '30px'
                    }}
                    src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                  />
                  <Select
                    onChange={handleChange}
                    showSearch
                    style={{
                      width: 200,
                    }}
                    placeholder="请搜索"
                  >
                    {SelectOptions}
                  </Select></div>
                <Button className="site-quit" type="link" onClick={this.handleQuit.bind(this)}>退出</Button>
              </div>
            </Header>
            <Content style={{ padding: '0 16px' }}>
              <div className="breadcrumb">
                <Breadcrumb style={{ margin: '16px 0' }}>
                  {
                    // eslint-disable-next-line array-callback-return
                    this.props.activeBreadcrumb.map((item, index) => {
                      if (item) {
                        if (item.path) {
                          return <Breadcrumb.Item key={index}><Link to={item.path}>{item.name}</Link></Breadcrumb.Item>
                        } else {
                          return <Breadcrumb.Item key={index}>{item.name}</Breadcrumb.Item>
                        }
                      }
                    })
                  }
                </Breadcrumb>
              </div>

              <div className="content">
                <InnerRouters />
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = state => {
  let menus = { ...state.toJS().MenuLeft };
  let isAuthor = localStorage.getItem('isAuthor');
  let templateMenu = {};
  if (Number(isAuthor)) {
    menus = { ...state.toJS().MenuLeft }
  } else {
    menus.leftMenuRouters = menus.leftMenuRouters.filter(v => v.path != '/Main/LiveCodeSetting')
  }
  console.log(menus)
  return {
    token: state.toJS().global.token,
    activeBreadcrumb: state.toJS().global.activeBreadcrumb,
    ...menus
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setBreadcrumb: (key) => dispatch(setBreadcrumb(key)),
    clearToken: () => dispatch(clearToken()),
    getConfig: () => dispatch(getConfigAsync()),
    initLeftMenuAction: () => dispatch(initLeftMenuAction())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Main)
