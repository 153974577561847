import React, { Component } from 'react'
import { Spin } from 'antd';
import './index.less'
export default class Index extends Component {
    render() {
        return (
            <div className="example">
            <Spin />
          </div>
        )
    }
}
