import React, { Component } from "react";
import { connect } from "react-redux";
import { setToken } from "../../store/actionCreator/global";
import { message } from 'antd';
import "./Login.less";
import { QrcodeOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { Form, Input, Button, Checkbox } from 'antd';
import httpClient from '../../request'

import { setExpire, getExpire } from '../../utils/index'
class Login extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      isCode: true,
      account: "",
      password: "",
    }
  }
  layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 16,
    },
  };
  tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };
  onFinishNodeLogin = (values) => {
    console.log('Success:', values);
    const {  username, password,remember } = values;
    this.getRequest({
      url:'',
      data: {
        username,
        password
      }
    },remember)
  };


  getRequest = async ({url,data},remember) => {
    try {
      let userInfo = await httpClient.request({
        url,
        type: 'post',
        data
      })
      if (userInfo) {
        if (remember) setExpire('userRemember',{...data,remember}, 1000 * 60 * 60 * 24 * 7);
      }


    } catch (error) {
      message.error({
        content: `请求错误-${error}`
      });
    }

  }

  onFinishFailedNotCodeLogin = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };


  onFinish(values) {
    console.log('Success:', values);
    this.props.setToken("fla113ljlfa90u131l3lj31");
    this.props.history.push("/Main/LoginSuccess");
  };

  onFinishFailed(errorInfo) {
    message.error('Failed:' + JSON.stringify(errorInfo));
  };

  createQrLoginBox() {
    let wxLogin = document.getElementById("wxLogin");
    if (!wxLogin) {
      let script = document.createElement("script");
      script.id = "wxLogin";
      script.src = "//rescdn.qqmail.com/node/ww/wwopenmng/js/sso/wwLogin-1.0.0.js";
      document.body.appendChild(script);
      script.onload = function () {
        let href = "https://sso.server.gaodun.com/index/v1/workwxlogin?url=" + encodeURIComponent(window.location.href);
        window.WwLogin({
          id: "wx_reg",
          appid: "ww3e4fc9b495ff0fd8",
          agentid: "1000109",
          redirect_uri: encodeURIComponent(href),
          state: "gaodun"
        });
      };
    }
  }


  componentDidMount() {

    let token = this.props.location.search.split("=")[1];
    if (token) {
      this.props.setToken(token);
      this.props.history.push('/Main/LoginSuccess');
      
    }
    this.createQrLoginBox();

  }

  componentWillUnmount() {
    this.removeWxLoginType();
  }

  removeWxLoginType = () => {
    if (document.getElementById("wxLogin")) {
      document.body.removeChild(document.getElementById("wxLogin"));
    }
  }

  handleLoginTypes = () => {
    this.setState({
      isCode: !this.state.isCode
    }, () => {
      const { isCode } = this.state;
      if (!isCode) {
        this.removeWxLoginType();
        let remberUserInfo = getExpire('userRemember');
        if (remberUserInfo) {
          if (remberUserInfo.remember) {
            if (this.formRef.current) {
              this.formRef.current.setFieldsValue({
                ...remberUserInfo,
              });
              //自动登录   
              this.getRequest({
                url:'',
                data: Object.assign({},remberUserInfo.username,remberUserInfo.password)
              },
              remberUserInfo.remember
              )
            }
          }

        } else {
          if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
              username: '',
              password: '',
              remember: false
            });
          }
        }
      }
    })
  }
  render() {
    const { isCode } = this.state;
    const isCodeHtml = isCode ? <AppstoreAddOutlined style={{ fontSize: '35px', color: "#0082EF" }} /> : <QrcodeOutlined style={{ fontSize: '35px', color: "#0082EF" }} />
    return (
      <div className="login-container">
        <div className="login">
          <div className="login-input" onClick={this.handleLoginTypes}>
            {isCodeHtml}
          </div>
          <h2 className="program-title">活码管理系统</h2>
          <div className="login-form" style={{ display: isCode ? 'block' : 'none' }} >
            <div id="wx_reg" />
          </div>
          <div className="login-form" style={{ display: isCode ? 'none' : 'block' }}>
            <Form
              {...this.layout}
              name="basic"
              initialValues={{
                remember: false,
              }}
              ref={this.formRef}
              onFinish={this.onFinishNodeLogin}
              onFinishFailed={this.onFinishFailedNotCodeLogin}


            >
              <Form.Item
                label="用户名"
                name="username"
                rules={[
                  {
                    required: true,
                    message: '请填写您的账号！',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[
                  {
                    required: true,
                    message: '请填写您的密码！',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...this.tailLayout} name="remember" valuePropName="checked" >
                <Checkbox >记住我</Checkbox>
              </Form.Item>
              <Form.Item style={{ justifyContent: 'center' }}>

                <Button type="primary" htmlType="submit"
                  style={{
                    width: '265px',
                    height: '36px',
                    marginLeft: '30px'
                  }} className="login-form-button">
                  登录
        </Button>


              </Form.Item>

            </Form>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {}
};
const mapDispatchToProps = dispatch => {
  return {
    setToken: (token) => dispatch(setToken(token))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
