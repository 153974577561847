export const getDataType = value => {
  //返回数据类型 [] => array , 0 => number,  "a" => string, {} => object 等;
  return Object.prototype.toString.call(value).slice(8, -1).toLowerCase()
};

export const baseBinary2File = (binary, fileName) => {
  // base64转file文件
  let arr = binary.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, {type: mime});
};

export const fileDownload = (url, fileName) => {

  let filename = fileName || "filename.xls";
  let tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = url;
  tempLink.setAttribute("download", filename);
  if (typeof tempLink.download === "undefined") {
    tempLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
};

export const isEmptyObject = obj => {
  return !Object.keys(obj).length
};

export const trim = str => {
  return str.replace(/(^\s*)|(\s*$)/g, "")
};



export const setExpire = (key, value, expire) => {
	let obj = {
	data: value,
	time: Date.now(),
	expire: expire
	};
	//localStorage 设置的值不能为对象,转为json字符串
	localStorage.setItem(key, JSON.stringify(obj));
}

export const getExpire = key => {
    let val = localStorage.getItem(key);
    if (!val) {
        return val;
    }
    val = JSON.parse(val);
    if (Date.now() - val.time > val.expire) {
        localStorage.removeItem(key);
        return null;
    }
    return val.data;
}
