export const   status= [
    {
        value: 0,
        name: '全部',
        type:"small",
        from:0
    }, {
        value: 1,
        name: '结束',
        type:"small",
        from:0
    }
    ,{
        value: 2,
        name: '使用中',
        type:"small",
        from:0
    }
]
export const  types= [
    {
    
        value: 0,
        name: '全部',
        type:"small",
        from:1
    },
    {
        value: 1,
        name: '个人',
        type:"small",
        from:1
    },
    {
        value: 2,
        name: '微信群',
        type:"small",
        from:1
    },
    {
        value: 3,
        name: 'APP',
        type:"small",
        from:1
    }
]

export const uploadItems={
    uploadShare:'uploadshare',
    uploadCode:'uploadCode',//个人活码
    uploadThreeCode:'uploadThreeCode',
    uploadAvatar:'uploadAvatar',
    uploadTemplate:'uploadTemplateAvatar'
}
// uploadTemplate

export const Code=[{ name:'CodeShare',key:1},{name:'getunionid',key:2}]