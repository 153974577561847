import {SET_TOKEN, CLEAR_TOKEN,INIT_LEFTMENU, SET_BREADCRUMB, SET_ACTIVETABKEY, GET_CONFIG,INIT_MENUROUTER} from "../actionTypes";
export const setToken = token => {
  return {
    type: SET_TOKEN,
    token
  }
};

export const clearToken = () => {
  return {
    type: CLEAR_TOKEN
  }
};

export const setBreadcrumb = key => {
  return {
    type: SET_BREADCRUMB,
    key
  }
};

export const setTabKey = activeIndex => {
  return {
    type: SET_ACTIVETABKEY,
    activeIndex
  }
};

const getConfig = config => {
  return {
    type: GET_CONFIG,
    config
  }
};


export const getMenuRouter=(value)=>({type:INIT_MENUROUTER,value})


export const getConfigAsync = () => {
  return dispatch => {
    // window.$axios.request({
    //   url: "/admin/v1/config"
    // }).then(res => {
    //   if(res.code === 200) {
    //     dispatch(getConfig(res.data))
    //   }
    // })
  }
};


export const initLeftMenuAction=()=>({type:INIT_LEFTMENU,value:true})