import React, { Component } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {CloseCircleOutlined} from '@ant-design/icons';

// 样式相关 代码
const grid = 8;
// 垂直样式
// const getItemStyle = (isDragging, draggableStyle) => ({
//     // some basic styles to make the items look a bit nicer
//     userSelect: "none",
//     padding: grid * 2,
//     margin: `0 0 ${grid}px 0`,
//
//     // change background colour if dragging
//     background: isDragging ? "lightgreen" : "grey",
//
//     // styles we need to apply on draggables
//     ...draggableStyle
// });
// const getListStyle = isDraggingOver => ({
//     background: isDraggingOver ? "lightblue" : "lightgrey",
//     padding: grid,
//     width: 250,
// });

// 水平样式
const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '10px',
    margin: `0 ${grid}px 14px 0`,
    position:'relative',
    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'rgba(242, 242, 242, 1)',
    borderRadius: '4px',
    
    // styles we need to apply on draggables
    ...draggableStyle,


});
const getListStyle = isDraggingOver => ({
    
    display: 'flex',
    padding: grid,
    overflow: 'auto',
    flexWrap:'wrap'

});

export default class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 1,//是群码的2个人的3，APP的
            items: [
                // { id: 'item-0', content: 'hello',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-1', content: 'I',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-2', content: 'am',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-3', content: '卡',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-4', content: '特',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-5', content: '洛',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-0', content: 'hello',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-1', content: 'I',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-2', content: 'am',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-3', content: '卡',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-4', content: '特',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-5', content: '洛',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },  { id: 'item-0', content: 'hello',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-1', content: 'I',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-2', content: 'am',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-3', content: '卡',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-4', content: '特',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-5', content: '洛',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },  { id: 'item-0', content: 'hello',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-1', content: 'I',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-2', content: 'am',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-3', content: '卡',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-4', content: '特',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' },
                // { id: 'item-5', content: '洛',url:'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png' }
            ]
        };
    }

    componentDidMount(){
      
    }
    // a little function to help us with reordering the result
    reOrder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = this.reOrder(
            this.props.drwerList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            items
        },()=>{
            this.props.drawerEndEvent(this.state.items)
            console.log()
        });
        
    }
    deleteInfoSelect=(item)=>{

            this.props.deleteDrawerInfo(item)
    }
    render() {
        const {type}=this.state;
        return (
            <div className="App">

                <DragDropContext onDragEnd={this.onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                            
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {this.props.drwerList.map((item, index) => (
                                    <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}
                                            >
                                               <div >
                                          <img width="35" height="35" style={{borderRadius:'5px',marginRight:'10px'}} src={item.url} alt="" /><span>{item.content}</span>
                                               </div>
                                                <div style={{position:'absolute',right:'-4px',top:'-7px'}}>
                                                <CloseCircleOutlined onClick={this.deleteInfoSelect.bind(this,item)}/>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

            </div>
        );
    }
}
