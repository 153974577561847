import React, { useEffect, useState } from 'react'
import './index.less'
import { Form, Input, Button, Select, Cascader, TreeSelect, Upload, Switch, Radio, Modal, Table, Divider, Empty, DatePicker, Space, Tag, Pagination, InputNumber, message, Checkbox, Spin } from 'antd';
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import Item from 'antd/lib/list/Item';
import QRCode from 'qrcode.react';
// import { DateRangePicker } from 'element-react';
// import 'element-theme-default';
import Drawer from '../../../../common/Drawer'
// import 'element-theme-default';
import * as moment from 'moment'
import ModelInfo from '../../../../common/Modle/index'
import UploadFile from '../../../../common/Upload/index';
import { uploadItems } from '../comm'
import Draggables from '../../../../common/Droppable/index'
import { types, Code } from '../comm'
import { get, post, postHttp, LabelGet, labelPost } from '../../../../request/axios';
import { baseUrl } from '../../../../request/axios'
import store from '../../../../store/index'
import TemplateOne from '../../../../common/img/bg.png'
import TemplateTwo from '../../../../common/img/bgTwo.png'
import TemplateThree from '../../../../common/img/TemplateThree.png'
import Avatar19 from "../../../../common/img/Avatar19.png";
import Avatar14 from "../../../../common/img/Avatar14.png";
import Avatar13 from "../../../../common/img/Avatar13.png";
import Avatar12 from "../../../../common/img/Avatar12.png";
import Avatar11 from "../../../../common/img/Avatar11.png";
import Avatar10 from "../../../../common/img/Avatar10.png";
import Avatar9 from "../../../../common/img/Avatar9.png";
import Avatar8 from "../../../../common/img/Avatar8.png";
import Avatar16 from "../../../../common/img/Avatar16.png";
import Avatar15 from "../../../../common/img/Avatar15.png";
import Avatar17 from "../../../../common/img/Avatar17.png";
import Avatar18 from "../../../../common/img/Avatar18.png";
import success from '../../../../common/img/success.png'
import lanCode from '../../../../common/img/lanCode.png'
import share from '../../../../common/img/share.png'
import RedCode from '../../../../common/img/redCode.png'
// import {} from ''
import axios from 'axios'
import Qs from 'qs'

import { Fragment } from 'react';
const { RangePicker } = DatePicker;
const { Search } = Input;
const dateFormat = 'hh:mm:ss';
const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

const imgsAvatar = [
    Avatar19,
    Avatar15,
    Avatar16,
    Avatar18,
    Avatar17,
    // Avatar20,
    Avatar8,
    Avatar9,
    Avatar9,
    Avatar10,
    Avatar11,
    Avatar12,
    Avatar14,
    Avatar13,
];
const liveCodeList = types.filter((item) => item.value != 0)
const { Option } = Select;
const onChange = (value, dateString) => {
    console.log('Selected Time: ', value);
    console.log('Formatted Selected Time: ', dateString);
}
const onOk = (value) => {
    console.log('onOk: ', value);
}

const layout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 16,
    },
};



let isStop = false;

export default function Index(props) {
    const [labelDataSource, setlabelDataSource] = useState([]);//设置Tree数组
    const [deleteLoading, setdeleteLoading] = useState(false)
    const [countTableTotal, setTotal] = useState(0);
    const [searTime, setSearTime] = useState({
        end_time: null,
        start_time: null,
    });
    const [labelInfo, setlabelInfo] = useState({})
    const [userInfoSearch, setUserInfoSearch] = useState({
        searchGroupId: '',
        searchCommnet: ''
    })
    const [RandomImg, setRandomImg] = useState([]);
    const [loadingAll, setLoadingAll] = useState(false)//这是全部信息的loading接下来是l
    const [loadingTime, setloadingTime] = useState(false)
    const [isVisble, setIsVisble] = useState(false);//设置左侧抽屉弹出的
    const [columnsTwo, setColumnsTwo] = useState([
        {
            title: "姓名",
            dataIndex: "name",
            key: "name",

            render: (text) => <span>{text}</span>,
        },
        {
            title: "工号",
            dataIndex: "userid",
            key: "userid",
            render: (text) => <span>{text}</span>,
        },
        {
            title: "微信号",
            dataIndex: "wxId",
            key: "wxId",
            render: (wxId) => <span>{wxId}</span>,
        },
        {
            title: "CRMID",
            dataIndex: "CRMID",
            key: "CRMID",
            render: (CRMID) => <span>{CRMID}</span>,
        },
        {
            title: "识别人数",
            dataIndex: "identifyNumber",
            key: "identifyNumber",
            render: (identifyNumber) => <span>{identifyNumber}</span>,
        },
        {
            title: "总分配人数",
            dataIndex: "sumNumber",
            key: "sumNumber",
            render: (sumNumber) => <span>{sumNumber}</span>,
        },
        {
            title: "未扫码分配",
            dataIndex: "notScan",
            key: "notScan",
            render: (notScan) => <span>{notScan}</span>,
        },
        {
            title: "进入页面人数",
            dataIndex: "isPage",
            key: "isPage",
            render: (isPage) => <span>{isPage}</span>,
        },
        {
            title: "已长按人数",
            dataIndex: "longNumber",
            key: "longNumber",
            render: (longNumber) => <span>{longNumber}</span>,
        },
        {
            title: "状态",
            dataIndex: "isstatus",
            key: "isstatus",
            //             render: status => <span>{status == 1 ? '结束' : '使用中'}</span>

            render: (isstatus) => (
                <Tag color={isstatus === 1 ? "error" : "success"}>
                    <span>{isstatus == 1 ? "结束" : "使用中"}</span>
                </Tag>
            ),
        },
        {
            title: "备注",
            dataIndex: "comments",
            key: "comments",
            render: (comments) => (

                <span>{comments}</span>
            ),
        },
        {
            title: "排班时间",
            dataIndex: "waitTime",
            key: "waitTime",
            render: (waitTime) => <span>{waitTime}</span>,
        },
        {
            title: "发布时间",
            dataIndex: "subTime",
            key: "subTime",

            render: (subTime) => (
                <span>{moment(subTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            title: "创建人",
            dataIndex: "createInfoName",
            key: "createInfoName",
            render: (createInfoName) => <span>{createInfoName}</span>,
        },
        {
            title: "最近操作时间",
            dataIndex: "nextTime",
            key: "nextTime",
            render: (nextTime) => (
                <span>{moment(nextTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</span>
            ),
        },
        {
            title: "操作人",
            dataIndex: "operatorInfo",
            key: "operatorInfo",
            render: (longNumber) => <span>{longNumber}</span>,
        },
        // 
        {
            title: "结束时间",
            dataIndex: "endTime",
            key: "endTime",
            render: (endTime) => (

                <span>{endTime ? moment(endTime * 1000).format("YYYY-MM-DD HH:mm:ss") : ''}</span>
            ),
        },
    ]);
    const [lanCodeTitle, setLanCodeTitle] = useState({
        title: '',
        initNumber: 0,
        show: false,
        time: [],
        info: ''
    })
    const [RedCodeTitle, setRedCodeTitle] = useState({
        title: '',
        initNumber: 0,
        show: false,
        time: [],
        info: ''
    })
    const [pationWx, setpationWX] = useState({
        page: 1,
        rows: 50
    })
    const [OriginSubject, setSubject] = useState(1);//主体的查询
    const [isOneInfo, setIsOneInfo] = useState(false);//这是originTYpe==1
    const [templateIds, settemplds] = useState(0);
    const [form] = Form.useForm();
    const [formUploadInfo] = Form.useForm();
    const [codeInfoIncrement] = Form.useForm();

    const [titleCode, settitle] = useState({
        title: '',
        topTitle: '',
        bottomTitleTop: '',
        bottomTItleBottom: ''
    })
    const [isAllchecked, setIsAllchecked] = useState(false)
    const [drwerList, setDrwerList] = useState([])
    var uploadOrigin = 0;
    const [uploadInfo, setUploadInfo] = useState({
        loading: false,
        imageUrl: ''
    })
    const [uploadTemplateAvatar, setUplaodTemplate] = useState({
        loading: false,
        imageUrl: ''
    })

    const [uploadAvatar, setUploadAvatar] = useState({
        loading: false,
        imageUrl: ''
    })
    const [uploadAvatarNext, setuploadAvatarNext] = useState({
        loading: false,
        imageUrl: ''
    })
    const [shareUpload, setShareUploadInfo] = useState({
        loading: false,
        shareImgUrl: ''
    })
    const [CodeDetail, setCodeDetail] = useState({
        CodeShare: false,
        getunionid: false,
        pollingMode: 1,
        template: 1,
        LiveCodeTypes: 0
    })
    const [Randk, setRank] = useState({
        id: "",
        startTime: "",
        endTime: "",
    });

    const [clinetLabelType, setClinetLabelType] = useState([]);//设置分类标签的处理
    const [isEdit, setIsEdit] = useState(false)
    const [isGroupModelInfo, setIsGroupModelInfo] = useState(false);//设置群吗手动设置
    const [selectionType, setSelectionType] = useState('checkbox');
    let [uid, setUid] = useState('');
    let [isEnable, setIsEnable] = useState(false);
    let [title, setModelTile] = useState('')
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [imageUrl, setImgUrl] = useState('')
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState([])
    const [isGroupModel, setGroupModel] = useState(false);
    const [selectedRowKeys, SetselectedRowKeys] = useState([]);//已经选了的信息
    const [options, setOptions] = useState([]);//配置信息
    const [originType, setOriginType] = useState(1);//这是表明活码的什么类型的
    const [items, setItems] = useState([]);//这是表明活码的什么类型的
    const [notSelect, SetNotSelect] = useState([]);//这是表明活码的什么类型的
    let [staff, setCtaff] = useState([])//设置所有的员工
    const [templateTypes, setTemplate] = useState(0)
    const [paramds, setparamds] = useState([])//设置下面对应员工的任务记录
    const [maxValue, setMaxValue] = useState('')
    const [pagtionall, setpagetionAll] = useState({
        page: 1,
        rows: 10
    });
    const [isModalVisibleDrawer, setIsModalVisibleDrawer] = useState(false);

    const [letLabelData, setLetLabelData] = useState([]);//设置左侧边框的DataSource

    useEffect(() => {
        localStorage.removeItem('CustomerIds')
        let uids = uid ? uid : localStorage.getItem('uid');
        if (uids) {
            setLoadingAll(true)
            getAllgroup('', '', 1, '', templateIds ? templateIds : 0, pagtionall.page, pagtionall.rows).then((res) => {
                setLoadingAll(false)
                const { data: { data: { result, totalCount } } } = res;
                const resultAll = result.map((v1) => {
                    return {
                        ...v1,
                        key: v1.id,
                        endTime: v1.workEndTime,
                        name: v1.remarks,
                        wxId: v1.wechat,
                        CRMID: v1.crmid,
                        identifyNumber: v1.totalNum,
                        sumNumber: Number(v1.simulation + v1.alreadyNum),
                        notScan: v1.simulation,
                        isPage: v1.alreadyNum,
                        longNumber: v1.pressNum,
                        isstatus: v1.status,
                        waitTime: v1.workStartTime + "--至--" + v1.workEndTime,
                        subTime: v1.createTime,
                        createInfoName: v1.createName,
                        nextTime: v1.updateTime,
                        operatorInfo: v1.updateName,
                        endTime: v1.closeTime,
                    };
                });
                setTotal(totalCount)
                setparamds([...resultAll]);
            }).catch((error) => { console.log(error) })
        }
    }, [pagtionall.page, pagtionall.rows, searTime.start_time, templateIds, uid, userInfoSearch])
    useEffect(() => {
        if (Randk.endTime && Randk.startTime) {
            handleSubmit();
        }
    }, [Randk.endTime, Randk.startTime])
    //查找父节点
    const familyTree = (arr1, id) => {
        var temp = []
        var forFn = function (arr, id) {
            for (var i = 0; i < arr.length; i++) {
                var item = arr[i]
                if (item.id === id) {
                    temp.push(item)
                    forFn(arr1, item.pid)
                    break
                } else {
                    if (item.children) {
                        forFn(item.children, id)
                    }
                }
            }
        }
        forFn(arr1, id)
        return temp
    }
    useEffect(() => {
        var result = [];
        for (let i = 0; i < 6; i++) {
            result.push(imgsAvatar[Math.floor(Math.random() * 13 + 1)]);
        }
        setRandomImg([...result]);
        /**
         * 不管配置还是不配置都分类的数据
         */
        get('admin/type/list?enable=2').then((res) => {
            const { data: { data } } = res;
            setOptions(data)
        }).catch((error) => {
            message.error(error?.info)
            // console.log(error)Eff
        })
        /**
         *首先先判断是编辑还是新增处理
         */
        // getCustomer();
        let { location: { pathname } } = props;
        let isOrigin = pathname.split('/');
        //如果是e则说明是编辑界面
        if (isOrigin[isOrigin.length - 1] == 'e') {
            setIsEdit(false)
            get("admin/livecode/details", { id: isOrigin[isOrigin.length - 2] }).then((res) => {
                const { data: { data } } = res;
                handleDetailInfo(data.labelTypeId);
                //设置标签系统
                form.setFieldsValue({
                    username: data.title,
                    templated: data.template,
                    pollingMode: data.polling,
                    LiveCodeTypes: data.type,
                    CodeShare: data.setup == 1 ? false : true,
                    getunionid: true,
                    type: data.typeids.split(',').map(v => Number(v)),
                    shareUrl: [{
                        url: data.thumbnail,
                    }]
                });
                console.log('data.typeids.split(', ').map(v => Number(v))', data.typeids.split(',').map(v => Number(v)))
                // start
                setOriginType(Number(data.type))
                handleTemplate({
                    target: {
                        value: data.template,
                    },
                });
                setShareUploadInfo({
                    ...shareUpload, shareImgUrl: data.thumbnail
                })

                const { type, template, temAvatar } = data;
                if (type == 1 && template == 1) {
                    setUplaodTemplate({
                        ...uploadTemplateAvatar,
                        imageUrl: data.temAvatar
                    })
                    form.setFieldsValue({
                        Avatar: [{
                            url: data.temAvatar
                        }],
                        temlateOneName: data.temTitle,
                        initShow: String(data.temBaseNum),
                        info: data.temDescription,
                        showTime: data.temTime == 1 ? false : true,
                        time: data.temTime != 1 ? moment(moment(data.temEndTime * 1000).format('YYYY-MM-DD HH:mm:ss')) : null
                    })
                } else if (type == 1 && template == 2) {
                    form.setFieldsValue({
                        templateTitle: data.temTitle,
                        templateTitleTop: data.temDescription,
                        templateTitleBottom: data.temDescription2,
                    });
                    settitle({
                        ...titleCode,
                        title: data.temTitle,
                        topTitle: data.temDescription,
                        bottomTitleTop: '',
                        bottomTItleBottom: data.temDescription2
                    })
                } else {
                    form.setFieldsValue({
                        temlateOneName: data.temTitle,
                        initShow: String(data.temBaseNum),
                        info: data.temDescription,
                        showTime: data.temTime == 1 ? false : true,
                        time: data.temTime != 1 ? moment(moment(data.temEndTime * 1000).format('YYYY-MM-DD HH:mm:ss')) : ''
                    })
                }


            }).catch((error) => {
                message.error(error?.info)
            })
            let id = isOrigin[isOrigin.length - 2];
            localStorage.setItem('uid', id)
            // getRemoteOriginWX(OriginSubject, '');
            //这是在获取下面的数据信息
            setUid(id)
            /**
             *在这里对数据进行初始化操作
             */
        } else {
            setIsEdit(true)
            localStorage.removeItem('uid')

            form.setFieldsValue({
                username: "",
                templated: '',
                pollingMode: '',
                LiveCodeTypes: 1,
                showTime: false,
                getunionid: false,
                CodeShare: false,
                info: '',
                initShow: '',
                temlateOneName: "",
                templateTitle: "",
                templateTitleTop: "",
                templateTitleBottom: ""
            });
            // //设置存储的信息
        }
    }, [])

    const handleDetailInfo = (labelTypeId) => {
        if (labelTypeId.length) {
            setClinetLabelType(labelTypeId.split(','))
            LabelGet('/labelClient/label/treelist', {
                typeId: labelTypeId.length ? clinetLabelType.join(',') : null
            }).then((res) => {
                // setlabelDataSource([]);
                let responseData = TreeGenderLabel(res.data.data);
                setlabelDataSource(responseData);
                initTableGroup('', 2, 2)
            })
        } else {
            initTableGroup('', 2, 2)
        }

    }

    /**
     * 获取企业微信信息
     */
    const getRemoteOriginWX = (type, keywords) => {
        get('admin/staff', {
            type: type,
            keywords: keywords,
            page: pationWx.page,
            rows: pationWx.rows
        }).then((res) => {
            const { data: { data: { result } } } = res;
            const newresult = result.map((v, i) => {
                return {
                    ...v,
                    id: v.id,
                    content: v.name,
                    url: v.avatar,
                    ischeck: false
                }
            })

            setItems((preState) => [...preState, ...newresult]);
        }).catch((error) => {
            message.error(error?.info)
        })
    }

    /**
     * 获取排班人员的信息
     */
    const getAllgroup = (endtime, keyWords, order = 1, startTime, status, page = 1, pagesize = 100,) => {
        return get('admin/livecodedetails/list', {
            lid: uid ? uid : localStorage.getItem('uid'),
            end_time: searTime.end_time,
            keywords: keyWords || userInfoSearch.searchGroupId,
            order: order,
            page: page,
            rows: pagesize,
            start_time: searTime.start_time,
            status: status,
            comments: userInfoSearch.searchCommnet,
        })
    }
    const handleTimeStatus = (e) => {
        console.log(e)
    }
    const handleRankPick = (e) => {
        return (item, module, info) => {
            if (info.range == 'end') {
                let Params = {
                    id: e.id,
                    startTime: module[0],
                    endTime: module[1],
                };
                setRank(Params);
            }

        }


    };
    const handleSubmit = (open) => {
        if (!open) {
            if (Randk.startTime && Randk.endTime) {
                setloadingTime(true)
                post("admin/livecodedetails/edit", {
                    id: Randk.id,
                    lid: uid,
                    workEndTime: Randk.endTime,
                    workStartTime: Randk.startTime,
                })
                    .then((res) => {
                        setloadingTime(false)
                        message.success("修改成功");
                        const index = staff.findIndex((item) => item.key == Randk.id);
                        let items = staff[index];
                        staff.splice(index, 1, {
                            ...items,
                            timeUpdate: [Randk.startTime, Randk.endTime],
                        });
                        setCtaff([...staff]);
                    })
                    .catch((error) => {
                        message.info(error.info);
                    });
            }
        }


    };
    const customerHandle = (value, a) => {
        setloadingTime(true)
        post("admin/livecodedetails/edit", {
            id: value.key,
            lid: uid,
            totalNum: a.value,
        })
            .then((res) => {
                setloadingTime(false)
                message.success("修改成功");
                const index = staff.findIndex((item) => item.key == value.key);
                let items = staff[index]
                staff.splice(index, 1, { ...items, limit: a.value });
                setCtaff([...staff]);
                initTableGroup('', 2, 2)
            })


    };
    const setHandle = (items) => {
        setUploadInfo({ ...uploadInfo, ...items })
    }
    const handleInputNumber = (text) => {
        return (e) => {
            customerHandle(text, { value: e })
        }
    }
    /**
     * 这是设置群吗的操作信息
     */
    const layoutGroupCode = {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 },
    };
    const tailLayoutGroupCode = {
        wrapperCol: { offset: 8, span: 16 },
    };
    const handleTree = (value) => {
        return (e, label, extra) => {

             if (extra.preValue.length > e.length) {
                setCtaff((preState) => {
                    updateTree(labelDataSource, e, value.id,extra.preValue)
                    return preState
                })
            } else{
                setCtaff((preState) => {
                    let isFind = preState.findIndex((v) => v.id == value.id)
                    preState.splice(isFind, 1, { ...preState[isFind], letLabelData: e })
                    return preState
                })
            }


        }
    }
    // const handleSettinglbabelType = (record) => {
    //     return (e) => {
    //         setlabelInfo({
    //             ...record
    //         })
    //         const Selectkeys = flatArray(labelDataSource, record.logId, 'log_id').map(v1 => v1.key)
    //         // setLetLabelData(Selectkeys);
    //     }
    // }

    const tProps = {
        treeData: labelDataSource,
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: '请选择',
        style: {
            width: '100%',
        },
    };

    const handlekeyDown = (e) => {
        e.preventDefault()
    }
    const TreeGenderLabel = (treeData, parent) => {
        if (!treeData) {
            return false
        }
        let Items = treeData.map((v1) => {
            return {
                ...v1,
                children: TreeGenderLabel(v1.children, v1),
                title: !parent ? v1.type_name : v1.label_name,
                disabled: !parent ? true : false,
                value: !parent ? `${v1.log_id}` : `${parent.log_id}-${v1.log_id}`,
                key: !parent ? `${v1.log_id}` : `${parent.log_id}-${v1.log_id}`,
            }
        })
        return Items;
    }
    const columns = [
        {
            title: `共${staff.length}个人数`,
            dataIndex: 'Info',
            width: '100px',
            render: (info) => <div style={{ display: 'flex', width: '167px' }}><img src={info.avatar} width="30" height="30" alt="" style={{ marginRight: '20px', borderRadius: '5px' }} /><span>{info.name}</span></div>,
        },
        {
            title: "添加客户上线",
            key: "limit",
            width: '100px',
            render: (text, record) => (
                <Space size="middle">
                    <InputNumber
                        min={1}
                        // max={text.limit}
                        value={text.limit}
                        onBlur={(e) => { customerHandle(text, e.target) }}
                        defaultValue={text.limit}
                        onStep={handleInputNumber(text)}
                    ></InputNumber>
                </Space>
            ),
        },
        {
            title: "设置标签",
            key: "letLabelData",
            width: '600px',
            render: (text, record) => {
                const letLabelDataItem = record.letLabelData.map((v) => v + '')
                return (
                    <TreeSelect maxTagCount={20} {...tProps} defaultValue={letLabelDataItem} onKeyDown={handlekeyDown} onChange={handleTree(record)} onBlur={handleTreeUpdateLabel(record)} />
                )
            }
        },
        {
            title: "排班时间",
            width: '300px',
            render: (text, record) => {
                // var newDate = new Date();
                // var year = newDate.getFullYear();
                // var month = newDate.getMonth() + 1;
                // var day = newDate.getDate();
                // var time = [];
                // var startTime = text.timeUpdate[0].split(':');
                // var endTime = text.timeUpdate[1].split(':');
                // time = [new Date(year, month, day, startTime[0], startTime[1], startTime[2]), new Date(year, month, day, endTime[0], endTime[1], endTime[2])]
                return (
                    // value={time}
                    // <DateRangePicker
                    //     value={time}
                    //     placeholder="选择日期范围"
                    //     isShowTime={true}
                    //     format="HH:mm:ss"
                    //     onChange={handleRankPick(text)}
                    // />
                    // <TimeRangePicker
                    // pickerWidth={300}

                    // // selectableRange={{start:'18:30:00',end: '20:30:00'}}

                    // onChange={handleRankPick(text)}
                    // placeholder="选择时间"

                    // />
                    <RangePicker
                        defaultValue={[
                            moment(text.timeUpdate[0], "HH:mm:ss"),
                            moment(text.timeUpdate[1], "HH:mm:ss"),
                        ]}
                        format="HH:mm:ss"
                        onCalendarChange={handleRankPick(text)}
                        showTime
                    />
                );
            },
        },
        {
            title: '备注',
            width: '250px',
            render: (text, record) => {
                return (
                    <div>
                        <Input defaultValue={text.info} onBlur={(e) => { handleBlur(e.target, text) }} placeholder="请输入备注内容"></Input>
                    </div>
                )
            }
        },
    ];
    const handleBlur = (e, value) => {
        setloadingTime(true)
        post('admin/livecodedetails/edit', {
            id: value.id,
            lid: uid,
            status: 2,
            comments: e.value
        }).then((res) => {
            setloadingTime(false)
            message.success('修改成功');
            setTimeout(function () {
                initTableGroup('', 2, 2);
                // InitTableAllCode(0);
            }, 500)
        }).catch((error) => {
            message.error(error?.info)
        })
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            SetselectedRowKeys([...selectedRowKeys])
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const data = [
    ]; // rowSelection object indicates the need for row selection


    const drawerEndHandle = (draweArr) => {
        const ids = draweArr.map((value) => value.id);
        post("admin/livecodedetails/sort", { id: ids.join(",") })
            .then((res) => {
                message.success("排序成功"); //
                initTableGroup('', 2, 2);
                //在这里需要请求下面的数据重新渲染
            })
            .catch((error) => {
                message.error(error?.info);
            });
    };
    const initTableGroup = (keywords, order = 1, orderByTwo = 2) => {
        let tempuid = uid ? uid : localStorage.getItem('uid')
        if (tempuid) {
            //1
            getAllgroup('', keywords, 1, '', 0).then((res) => {
                const { data: { data: { result } } } = res;
                const resultAll = result.map((v1) => {
                    return {
                        ...v1,
                        key: v1.id,
                        endTime: v1.workEndTime,
                        name: v1.remarks,
                        wxId: v1.wechat,
                        CRMID: v1.crmid,
                        identifyNumber: v1.totalNum,
                        sumNumber: Number(v1.simulation + v1.alreadyNum),
                        notScan: v1.simulation,
                        isPage: v1.alreadyNum,
                        longNumber: v1.pressNum,
                        isstatus: v1.status,
                        waitTime: v1.workStartTime + "--至--" + v1.workEndTime,
                        subTime: v1.createTime,
                        createInfoName: v1.createName,
                        nextTime: v1.updateTime,
                        operatorInfo: v1.updateName,
                        endTime: v1.closeTime,
                    };
                });
                setparamds([])
                setparamds([...resultAll]);
            }).catch((error) => { console.log(error) })
            //获取正在使用的
            getAllgroup('', keywords, orderByTwo, '', 2).then((res) => {
                const { data: { data: { result } } } = res;
                let newresult = result.map((v, i) => {
                    return {
                        id: v.id + '',
                        url: v.avatar || v.qrcode,
                        content: v.name
                    }
                })


                let newGroup = result.map((v1, i1) => {
                    return {
                        ...v1,
                        key: v1.id,
                        Info: {
                            avatar: v1.avatar || v1.qrcode,
                            name: v1.remarks
                        },
                        logId: v1.logId,
                        letLabelData: [],
                        limit: v1.totalNum,
                        timeUpdate: [v1.workStartTime, v1.workEndTime],
                        info: v1.comments
                    }
                })
                //
                //这里是为了获取已经选择过的
                handleUpdateTreeData(newGroup);
                setDrwerList(newresult)
            }).catch((error) => { console.log(error) })
        }

    }
    //设置所在标签的
    const handleUpdateTreeData = (newGroup) => {
        let tempReuslt = [];
        setlabelDataSource((preState) => {
            tempReuslt = JSON.parse(JSON.stringify(preState));
            return preState
        })
        let afterDataSource = newGroup.map((v2) => {
            return {
                ...v2,
                letLabelData: flatArray(tempReuslt, v2.logId, 'log_id').map(v1 => v1.key)
            }
        })
        console.log('afterDataSource', afterDataSource)
        setCtaff(afterDataSource)
    }
    const handleOk = () => {
        //这里处理的所有数据情况
        if (originType == 1 || originType == 2) {
            setIsModalVisible(false);
            let newParams = notSelect.map((v, i) => {
                let isHas = Object.keys(v).findIndex(v => v == 'mobile')
                return {
                    ...v,
                    avatar: v.avatar,
                    expirationTime: 0,
                    lid: uid ? uid : localStorage.getItem('uid'),
                    phone: v.mobile,
                    //这个判断是如果有渠道码直接赋值如果没有在判断是不是选择高顿的之后判断是不是自己上传的
                    qrcode: v.channel_code ? v.channel_code : (isHas != -1 ? v.qr_code : v.url),
                    remarks: v.name ? v.name : v.content,
                    userid: v.userid ? v.userid : v.useId,
                    manual: isHas != -1 ? 0 : 1
                }
            })
            console.log(newParams, 'newParams')
            postHttp('admin/livecodedetails/addall', [
                ...newParams
            ]).then((res) => {
                message.success('添加成功')
                SetNotSelect([])
                initTableGroup();
                get("admin/livecode/details", { id: uid ? uid : localStorage.getItem('uid') }).then((res) => {
                    // console.log(res)
                    const { data: { data: { labelTypeId } } } = res;
                    handleDetailInfo(labelTypeId)
                })
                /**
                 * 设置标签handleDetailInfo
                 */
            }).catch((error) => {
                message.error('添加失败')
                console.log(error)
            })
        } else {
            setIsModalVisible(false);
        }
    };

    const Format = (d) => {

        return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate() + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    }
    const handleCancel = () => {
        setIsModalVisible(false);
        if (originType == 1) {
            if (drwerList.length == 0) {
                // getCustomer();
                setIsAllchecked(false)
                SetNotSelect([])
            }
        } else if (originType == 0) {
            codeInfoIncrement.setFieldsValue({
                CRMID: "", wxName: "", infoCodeUserName: ""
            })
            setUploadAvatar({
                ...uploadAvatar, imageUrl: ''
            })
            setuploadAvatarNext({
                ...uploadAvatar,
                imageUrl: ''
            })
        }
    };

    const onFinish = (values) => {
        if (isEdit) {
            post('admin/livecode/add', {
                polling: values.pollingMode,
                setup: values.CodeShare ? 2 : 1,
                snsapi: 2,
                template: values.templated,
                thumbnail: shareUpload.shareImgUrl,
                title: values.username,
                type: values.LiveCodeTypes,
                typeid: values.type[values.type.length - 1],
                typeids: values.type.join(','),
                temAvatar: originType == 1 && templateTypes == 1 ? uploadTemplateAvatar.imageUrl : '',
                temBaseNum: values.initShow,
                temDescription: templateTypes == 1 && originType == 1 ? values.info : templateTypes == 2 && originType == 1 ? values.templateTitleTop : templateTypes == 1 && originType == 2 ? values.info : '',
                temDescription2: templateTypes == 2 && originType == 1 ? values.templateTitleBottom : '',
                temEndTime: values.time ? (originType == 1 && templateTypes == 1 ? moment(Format(values.time._d)).valueOf() / 1000 :
                    originType == 2 && templateTypes == 1 ? moment(Format(values.time._d)).valueOf() / 1000 : '') : '',
                temTime: values.showTime ? 2 : 1,
                temTitle: originType == 1 && templateTypes == 2 ? values.templateTitle : values.temlateOneName

            }).then((res) => {

                message.success("添加成功");
                const { data: { data: { id } } } = res;
                setUid(id)
                localStorage.setItem('uid', id)
                setIsEdit(false)
                getAllgroup('')
                //    data.typeids.split(',').map(v => Number(v))
            }).catch((error) => {
                message.error(error?.info)
            })

            // getRemoteOriginWX(OriginSubject, '')
        } else {
            // values.getunionid ? 2 : 1
            post('admin/livecode/edit', {
                polling: values.pollingMode,
                setup: values.CodeShare ? 2 : 1,
                snsapi: 2,
                template: values.templated,
                thumbnail: shareUpload.shareImgUrl,
                title: values.username,
                id: uid ? uid : localStorage.getItem('uid'),
                type: values.LiveCodeTypes,
                typeid: values.type[values.type.length - 1],
                typeids: values.type.join(','),
                temAvatar: originType == 1 && templateTypes == 1 ? uploadTemplateAvatar.imageUrl : '',
                temBaseNum: values.initShow,
                temDescription: templateTypes == 1 && originType == 1 ? values.info : templateTypes == 2 && originType == 1 ? values.templateTitleTop : templateTypes == 1 && originType == 2 ? values.info : '',
                temDescription2: templateTypes == 2 && originType == 1 ? values.templateTitleBottom : '',
                temEndTime: values.time ? (originType == 1 && templateTypes == 1 ? moment(Format(values.time._d)).valueOf() / 1000 :
                    originType == 2 && templateTypes == 1 ? moment(Format(values.time._d)).valueOf() / 1000 : '') : '',
                temTime: values.showTime ? 2 : 1,
                temTitle: originType == 1 && templateTypes == 2 ? values.templateTitle : values.temlateOneName
            }).then(
                (res) => {
                    message.success('修改成功');
                    // ;
                    props.history.goBack();
                }
            ).catch((error) => {
                console.log(error.info)
            })
        }

        localStorage.setItem('CustomerIds', JSON.stringify(values.type))

    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const handleChange = (type) => {
        return (info) => {
            // debugger
            uploadOrigin = type;
            if (info.file.status === 'uploading') {
                if (type == uploadItems.uploadAvatar) {
                    // originType==uploadItems.uploadAvatar
                    setUploadAvatar({ ...uploadAvatar, loading: true })
                } else if (type == uploadItems.uploadShare) {
                    setShareUploadInfo({ ...shareUpload, loading: true })
                    console.log(shareUpload)
                } else if (type == uploadItems.uploadCode) {//个人活码
                    setUploadInfo({ ...uploadInfo, loading: true })
                } else if (type == uploadItems.uploadTemplate) {
                    setUplaodTemplate({
                        ...uploadTemplateAvatar, loading: true
                    })
                } else { setuploadAvatarNext({ ...uploadAvatarNext, loading: true }) }

                //   setHandle({loading:true})
                return;
            }
            if (info.file.status === 'done') {
                console.log(info.file.response)
                getBase64(info.file.originFileObj, imageUrl => {
                    const { data: { url } } = info.file.response
                    if (type == uploadItems.uploadAvatar) {
                        setUploadAvatar({ ...uploadAvatar, loading: false })
                        setUploadAvatar({ ...uploadAvatar, imageUrl: url })
                    } else if (type == uploadItems.uploadShare) {
                        setShareUploadInfo({ ...shareUpload, loading: false, shareImgUrl: url })
                        // setShareUploadInfo({ ...shareUpload,  })

                    } else if (type == uploadItems.uploadCode) {
                        setUploadInfo({ ...uploadInfo, loading: false, imageUrl: url })
                        // setUploadInfo({ ...uploadInfo,)
                    } else if (type == uploadItems.uploadTemplate) {

                        setUplaodTemplate(
                            {
                                ...uploadTemplateAvatar, loading: false,
                                imageUrl: url
                            }
                        )
                    } else {
                        setuploadAvatarNext({ ...uploadAvatar, loading: false });
                        setuploadAvatarNext({ ...uploadAvatar, imageUrl: url })
                    }
                }
                );
            }
        }
    };


    const deleteDrawerInfo = (item) => {
        setdeleteLoading(true)
        post('admin/livecodedetails/edit', {
            id: Number(item.id),
            lid: uid,
            status: 1
        }).then((res) => {
            setdeleteLoading(false)
            message.success('删除成功')
            let index = drwerList.findIndex((v) => v.id == item.id);//查找原来位置
            drwerList.splice(index, 1);
            setDrwerList([...drwerList]);//删除结束以后要重新标记一下当前的状态
            let notIndex = notSelect.findIndex((v) => v.id == item.id);
            notSelect.splice(notIndex, 1);//将这项条件从已选中的状态中取消
            SetNotSelect([...notSelect])
            let allSelectListIndex = items.findIndex((v) => v.id == item.id);
            item.ischeck = false
            items.splice(allSelectListIndex, 1, item)
            setItems([...items]);
            let staffIndex = staff.findIndex((v) => v.id == item.id);
            staff.splice(staffIndex, 1);
            setCtaff([...staff]);//当前状态
            let filteritems = paramds.filter((v) => v.key == item.id);
            let filteritemsIndex = paramds.findIndex((v) => v.key == item.id);
            if (!!filteritems.length) {
                filteritems[0].isstatus = 1;//假设1是已结束的状态
                console.log(filteritems)
                paramds.splice(filteritemsIndex, 1, filteritems[0]);
                console.log(paramds)

                setparamds([...paramds])
            }
        }).catch((error) => {
            message.error(error)
        })

    }
    const setIsModalVisibleLimit = () => {
        setIsEnable(false);
        setloadingTime(true)
        const ids = selectedRowKeys.map(v => v);
        post("admin/livecodedetails/totalnum", {
            ids: ids.join(","),
            lid: uid ? uid : localStorage.getItem("uid"),
            totalNum: maxValue,
        }).then((res) => {
            setloadingTime(false)
            message.success('修改成功')
            getAllgroup('', '', 2, '', 2).then((res) => {
                const { data: { data: { result } } } = res;
                let newGroup = result.map((v1, i1) => {
                    return {
                        ...v1,
                        key: v1.id,
                        Info: {
                            avatar: v1.avatar,
                            name: v1.remarks
                        },
                        logId: v1.logId,
                        letLabelData: [],
                        limit: v1.totalNum,
                        timeUpdate: [v1.workStartTime, v1.workEndTime],
                        info: v1.comments
                    }
                })
                setCtaff([])
                handleUpdateTreeData(newGroup);
            })
            SetselectedRowKeys([])
        }).catch((error) => {
            message.error(error?.info)
        })
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }
    const Deluece = (fn, wait = 1000) => {
        var timer = null;

        return function () {
            var context = this
            var args = arguments
            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(function () {
                fn.apply(context, args)
            }, wait)
        }
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片必须小于2MB');
        }
        return isJpgOrPng && isLt2M;
    }

    const isOpenShowModelAddGroup = () => {

    }

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const incrementInfo = () => {
        const types = form.getFieldValue('type');
        let typeId = types[types.length - 1];
        const parentIds = familyTree(options, typeId);
        // console.log()
        let { subject } = parentIds[parentIds.length - 1];
        setSubject(subject)
        getRemoteOriginWX(subject, '');
        setItems((preState) => {
            const newPreState = preState.length ? preState.map((v, i) => {
                return {
                    ...v,
                    ischeck: false
                }
            }) : []
            return newPreState
        })
        if (originType == 1) {
            setIsAllchecked(false)
            SetNotSelect([])
            setIsModalVisible(true)
            setUploadAvatar({
                ...uploadAvatar,
                imageUrl: ''
            })
            CreateRef();
        } else if (originType == 2) {
            codeInfoIncrement.setFieldsValue({
                CRMID: "", wxName: "", infoCodeUserName: ""
            })
            setUploadAvatar({
                ...uploadAvatar, imageUrl: ''
            })
            setuploadAvatarNext({
                ...uploadAvatar,
                imageUrl: ''
            })
            setIsModalVisible(true)
            CreateRef();
        } else {
            setuploadAvatarNext({
                ...uploadAvatarNext,
                imageUrl: ''
            })
            setUploadAvatar({
                ...uploadAvatar,
                imageUrl: ""
            })

            //             uploadAvatar.imageUrl，
            // uploadAvatarNext.imageUrl
            setIsOneInfo(true)
        }

    }

    const uploadButton = () => {
        return (
            <div>
                {/* {result} */}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        )
    }

    const tosend = () => {

    }

    const SearchaBlue = (name) => {
        return (e) => {
            e.preventDefault();
            var obj = {};
            obj[name] = e.target.value;
            setUserInfoSearch({
                ...userInfoSearch,
                ...obj
            })
        }
    }
    const handleManualClose = (params) => {
        console.log(params)
        setIsGroupModelInfo(false)

    }


    const searchGroupName = (e) => {
        console.log(e)
        if (e.value.length == 0) {
            const types = form.getFieldValue('type');
            let typeId = types[types.length - 1];
            const parentIds = familyTree(options, typeId);
            // console.log()
            let { subject } = parentIds[parentIds.length - 1];
            getRemoteOriginWX(subject, '')
            // getCustomer();
        } else {
            console.log(e)
            setpationWX({
                ...pationWx,
                page: 1
            })
            // getRemoteOriginWX(OriginSubject, )
            get('admin/staff', {
                type: OriginSubject,
                keywords: e.value.trim(),
                page: pationWx.page,
                rows: pationWx.rows
            }).then((res) => {
                const { data: { data: { result } } } = res;
                const newresult = result.map((v, i) => {
                    return {
                        ...v,
                        id: v.id,
                        content: v.name,
                        url: v.avatar,
                        ischeck: false
                    }
                })
                setItems([])
                setItems([...newresult])
            }).catch((error) => {
                console.log(error.info)
            })
        }

    }

    const deletSelectInfo = (v) => {
        return () => {
            let index = items.findIndex((item) => item.id == v.id);
            let indexNot = notSelect.findIndex((item) => item.id == v.id);
            v.ischeck = false;
            notSelect.splice(indexNot, 1);
            SetNotSelect([...notSelect])
            items.splice(index, 1, v)
            setItems([...items])
            if (notSelect.length == 0) {
                setIsAllchecked(false)
            }
        }
    }

    const handleSelectCancle = (item) => {
        return (e) => {
            // debugger
            let findItem = items.findIndex((i) => i.id == item.id);
            let updateItems = { ...item, ischeck: e.target.checked };
            items.splice(findItem, 1, updateItems);
            setItems([...items]);
            console.log(items.length);
            let NotNewSelect = [
                ...items.filter((v) => v.ischeck != false),
                ...notSelect,
            ];
            NotNewSelect = Reucer(NotNewSelect);
            SetNotSelect([...NotNewSelect]);
        };
    };

    /**
     * 
     * @param { name 
     */
    const handleSwictch = (name) => {
        return (e) => {
            if (name == 'LiveCodeTypes') {
                setOriginType(e)
            }
            if (name == 'template' || name == 'pollingMode') {
                CodeDetail[name] = e.target.value;
            } else {
                CodeDetail[name] = e
            }
            setCodeDetail({ ...CodeDetail, ...{ ...CodeDetail } })
        }
    }

    const handlebtnFilter = (name) => {
        return (e, dateString) => {
            console.log(name);
            console.log(searTime)
            if (name == "time") {
                setSearTime({
                    ...searTime,
                    start_time: moment(dateString[0]).valueOf() / 1000,
                    end_time: moment(dateString[1]).valueOf() / 1000,
                });

            } else {
                settemplds(e.target ? e.target.value : 0)
            }

        };
    };

    const updateAllResh = (e) => {
        setloadingTime(true)
        getAllgroup('', e, 2, '', 2).then((res) => {
            setloadingTime(false)
            if (res.data) {
                const { data: { data: { result } } } = res;
                let newGroup = result.map((v1, i1) => {
                    return {
                        ...v1,
                        key: v1.id,
                        Info: {
                            avatar: v1.avatar,
                            name: v1.remarks
                        },
                        limit: v1.totalNum,
                        timeUpdate: [v1.workStartTime, v1.workEndTime],
                        info: v1.comments
                    }
                })
                setCtaff(newGroup)
            }

        }).catch((error) => { console.log(error) })
    }

    /** */
    const handleupperLimit = (e) => {

        if (e.value != '') {
            if (isStop) {
                return false;
            }
            isStop = true;
            setTimeout(() => {
                isStop = false;
            }, 2000);

            Deluece(updateAllResh(e.value.trim()), 200)
            // initTableGroup();

            // setCtaff([...staff.filter((v) => v.content == e.target.value || v.key == Number(e.target.value))])
        } else {
            initTableGroup();
        }
    }

    const hanldeCheckAll = (e) => {
        setIsAllchecked(e.target.checked)
        if (e.target.checked) {
            const newItems = items.map((v, i) => {
                return {
                    ...v,
                    ischeck: true
                }
            })
            setItems(newItems)
            SetNotSelect(newItems)
        } else {
            // getCustomer();
            SetNotSelect([])
        }
    }

    const layoutMoldeInfo = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };
    const tailLayoutModelInfo = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const batchAllUpdate = () => {
        if (selectedRowKeys.length > 0) {
            setIsEnable(true)
        } else {
            message.error('请先选择该用户')
        }
    }
    const Reucer = (departTag) => {
        var result = [];
        var obj = {};
        for (var i = 0; i < departTag.length; i++) {
            if (!obj[departTag[i].id]) {
                result.push(departTag[i]);
                obj[departTag[i].id] = true;
            }
        }
        return result;
    };
    {/* onFinishAddGroup,onFinishFailedGroup */ }
    const onFinishAddGroup = (res) => {
        setIsGroupModelInfo(false);
        // debugger
        let params = {
            id: get_uuid() + "LiveCode",
            useId: res.NationalityID,
            content: res.username,
            url: originType == 1 ? uploadAvatar.imageUrl : uploadInfo.imageUrl,
            channel_code: uploadInfo.imageUrl,
            avatar: originType == 1 ? uploadAvatar.imageUrl : uploadInfo.imageUrl,
            ischeck: true
        };
        SetNotSelect([...notSelect, params]);
    };
    const onFinishFailedGroup = (values) => {
        setIsGroupModelInfo(true)
    }
    const openGroupEnable = () => {
        if (originType == 1) {
            formUploadInfo.resetFields();
            formUploadInfo.setFieldsValue({
                username: '',
                NationalityID: ''
            })
            setUploadAvatar({
                ...uploadAvatar, imageUrl: ''
            })
            // uploadInfo.imageUrl
            setUploadInfo({
                ...uploadInfo,
                imageUrl: ''
            })
            setIsGroupModelInfo(true)
        } else if (originType == 2) {
            formUploadInfo.resetFields();
            formUploadInfo.setFieldsValue({
                username: ''
            })
            setUploadInfo({
                ...uploadInfo,
                imageUrl: ''
            })
            setIsGroupModelInfo(true)
        } else {
            codeInfoIncrement.resetFields();
            codeInfoIncrement.setFieldsValue({
                CRMID: "", mwxName: "", infoCodeUserName: ""
            })
            setUploadInfo({
                ...uploadInfo,
                imageUrl: ''
            })
        }


    }
    const CreateRef = () => {
        setTimeout(function () {
            var demo = document.getElementById("scrollItems");
            if (!!demo) {
                //事件监听并增加防抖操作
                demo.addEventListener("scroll", function (e) {
                    const { scrollTop, clientHeight, scrollHeight } = e.target;
                    if (clientHeight + scrollTop == scrollHeight) {
                        if (isStop) {
                            return false;
                        }
                        isStop = true;
                        setTimeout(() => {
                            isStop = false;
                        }, 2000);
                        setpationWX({
                            ...pationWx,
                            page: (pationWx.page += 1),
                            rows: 20,
                        });
                        const types = form.getFieldValue('type');
                        let typeId = types[types.length - 1];
                        const parentIds = familyTree(options, typeId);
                        let { subject } = parentIds[parentIds.length - 1];
                        getRemoteOriginWX(subject, '')



                    }
                });
            }
        }, 1000);

        // console.log(demo)
    };

    /**
     * 这是群码中的个人app的COde
     * @param {}} value 
     */
    const onFinsishgetApp = (value) => {
        post("admin/livecodedetails/add", {
            lid: uid ? uid : localStorage.getItem('uid'),
            remarks: value.infoCodeUserName,
            qrcode: value.infoCodeUrl.file.response.data.url,
            avatar: value.portrait.file.response.data.url,
            crmid: value.CRMID,
            wechat: value.wxName,
            manual: 1
        })
            .then((res) => {
                message.success("添加成功");
                setIsOneInfo(false);
                codeInfoIncrement.resetFields();
                get("admin/livecode/details", { id: uid ? uid : localStorage.getItem('uid') }).then((res) => {
                    const { data: { data: { labelTypeId } } } = res;
                    handleDetailInfo(labelTypeId)
                })

            })
        setTimeout(() => {
            initTableGroup('', 2, 2)
        }, 200)

    };
    const onFiledInfo = (value) => {

    }

    //这是设置模板
    const handleTemplate = (r) => {
        const { target: { value } } = r;
        setTemplate(value)
        // initShow，showTime，info，time，temlateOneName，uploadTemplateAvatar.imageUrl
        form.setFieldsValue({
            showTime: false,
            info: '',
            initShow: '',
            temlateOneName: "",
            templateTitle: "",
            templateTitleTop: "",
            templateTitleBottom: "",
            time: undefined
        });
        setUplaodTemplate({
            ...uploadTemplateAvatar,
            imageUrl: ''
        })
        // setUploadAvatar
    }

    const closeMo = () => {
        codeInfoIncrement.setFieldsValue({
            CRMID: "", wxName: "", infoCodeUserName: ""
        })
        setUploadAvatar({
            ...uploadAvatar, imageUrl: ''
        })
        setuploadAvatarNext({
            ...uploadAvatar,
            imageUrl: ''
        })
        setIsOneInfo(false)
    }
    const filter = (inputValue, path) => {
        // debugger
        return path.some(option => option.typeName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
    const OriginTypeTwoHandle = () => {
        setIsGroupModelInfo(false)
        formUploadInfo.resetFields();
    }
    const handleMaxValue = (e) => {
        if (e) {
            setMaxValue(e)
        }

    }
    // //这是分页以后数据变化事件
    const handlePageChange = (page, pageSize) => {
        setpagetionAll({
            ...pagtionall,
            page: page,
            rows: pageSize
        })
    };
    const CloseOriginTypeOne = () => {
        setIsOneInfo(false)
        codeInfoIncrement.resetFields();
    }
    const normFile = (e) => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const handleInputRedCode = (e, name) => {
        var temp = {}
        if (name == 'show') {
            temp[name] = Number(e)
            if (!e) {
                form.setFieldsValue({
                    time: null
                })
            }
        } else {
            temp[name] = e.value
        }
        setRedCodeTitle({
            ...RedCodeTitle,
            ...temp
        })

    }

    const handleInputLanCode = (e, name) => {
        var temp = {}
        if (name == 'show') {
            temp[name] = Number(e)
            if (!e) {
                form.setFieldsValue({
                    time: null
                })
            }


        } else {
            temp[name] = e.value
        }


        setLanCodeTitle({
            ...lanCodeTitle,
            ...temp
        })
    }

    const S4 = () => {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    const get_uuid = () => {
        return (
            S4() +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            "-" +
            S4() +
            S4() +
            S4()
        );
    };
    const handleReset = () => {
        if (isEdit) {
            form.resetFields();//重置所有的操作
            form.setFieldsValue({
                showTime: false
            })
            setOriginType()
            // {shareUpload.shareImgUrl
            setShareUploadInfo({
                ...shareUpload,
                shareImgUrl: ''
            })
            setUplaodTemplate({
                ...uploadTemplateAvatar,
                imageUrl: ''
            })
        } else {
            props.history.goBack();
        }
        console.log('---q取消')

    }
    const handleInput = (e, name) => {
        let result = [];
        if (!name) {
            if (e.value.length > 8) {
                result.push(e.value.substr(0, 6))
                result.push(e.value.substr(6, e.value.length))
            } else {
                result.push(e.value)
            }
            settitle({
                ...titleCode,
                bottomTitleTop: result[0],
                bottomTItleBottom: result[1]
            })
        } else {
            if (name == 'title') {
                settitle({
                    ...titleCode,
                    title: e.value
                })
            } else if (name == 'topTitle') {
                settitle({
                    ...titleCode,
                    topTitle: e.value
                })
            }
        }
        console.log(result)
    }
    /**
     * 设置model的返回值
     */
    //批量设置取消按钮
    const CanValOpenCloseAll = () => {
        setIsEnable(false)
        SetselectedRowKeys([])
    }
    //设置个人码
    const CanValGroupModelClose = () => {
        setGroupModel(false)
    }
    const CanValOpenCloseModelInfo = () => {
        setIsGroupModelInfo(false)
    }
    const CanValOpenCloseOrigin = () => {
        setIsOneInfo(false)
    }
    const disabledDate = (current) => {
        return current > moment().subtract(0, 'day') || current < moment().subtract(1, "days");
    }


    const flatArray = (SourceData, targetData, key = 'key') => {
        return SourceData.map((v1) => v1.children).flat(Infinity).filter((v2) => targetData.includes(v2[key]))
    }
    const updateTree = (labelData, dataSource, ldid,[preState]) => {
            let params = flatArray(labelData, dataSource.length?dataSource:[preState['value']]).map((v1) => {
                return {
                    ...v1,
                    lid: +uid,
                    ldid: ldid,
                    log_id: dataSource.length?v1.log_id:0
                }
            })
            if(params.length){
                labelPost('/admin/livecodedetails/label',params).then((res) => {
                    // setIsModalVisibleDrawer(false)
                    message.success('设置成功');
                    initTableGroup('', 2, 2);
                }).catch((error) => {
                    message.error('设置失败')
                })    
            }
           
    

    }
    const handleTreeUpdateLabel = (value) => {
        return (e) => {
            setCtaff((preState) => {
                let isDataSourceIndex = preState.findIndex(v => v.id == value.id);
                updateTree(labelDataSource, preState[isDataSourceIndex]['letLabelData'], value.id,[{}])
                return preState
            })
        }
    }
    return (
        <div className="layout-container">

            <Form
                {...layout}
                labelAlign={'left'}
                name="basic"
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="containers">

                    <Form.Item
                        label="名称"
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: '请输入名称',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="分类"
                        name="type"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}
                    >

                        <Cascader
                            disabled={!isEdit ? true : false}
                            changeOnSelect
                            options={options} placeholder="全部"
                            showSearch={{ filter }}

                            fieldNames={{ label: 'typeName', value: 'id', children: 'children' }} />

                    </Form.Item>
                    <Form.Item
                        label='圈子分享图'
                        name="shareUrl"
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[
                            {
                                required: true,
                                message: '请上传图片',
                            },
                        ]}
                    >
                        {/*                            fileList={{fileList}} */}
                        <Upload
                            disabled={!isEdit ? true : false}
                            id='uploadOne'
                            data-type='0'
                            name="file"

                            data={{
                                savePath: 'LiveCode'
                            }}
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            headers={
                                {
                                    token: store.getState().toJS().global.token
                                }
                            }
                            action={`${baseUrl}admin/uploads`}
                            beforeUpload={beforeUpload}
                            onChange={handleChange(uploadItems.uploadShare)}
                        >
                            {shareUpload.shareImgUrl ? <img src={shareUpload.shareImgUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                        </Upload>
                    </Form.Item>
                    <Form.Item

                        label="活码类型"
                        name="LiveCodeTypes"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}

                    >
                        <Select disabled={!isEdit ? true : false} placeholder="请选择" value={CodeDetail.LiveCodeTypes} onChange={handleSwictch('LiveCodeTypes')}>
                            <Option value={1} >个人</Option>
                            <Option value={2} >微信群</Option>
                            <Option value={3} >App</Option>

                        </Select>
                    </Form.Item>
                    <Form.Item
                        style={{
                            marginBottom: '20px'
                        }}
                        valuePropName="checked"
                        className="notFlex"
                        label="同一个微信号只能识别一个二维码"
                        name="CodeShare"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}
                        labelCol={{ span: 4 }}
                    >
                        <Switch checkedChildren="开" unCheckedChildren="关" onChange={handleSwictch('CodeShare')} className="switchType" style={{ position: 'relative' }} />
                    </Form.Item>
                    <Form.Item
                        label="轮询方式"
                        className="notFlex"
                        name="pollingMode"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}
                    >
                        <Radio.Group onChange={handleSwictch('pollingMode')}>
                            <Radio value={1}>AABBCC</Radio>
                            <Radio value={2}>先平均ABCABCABC</Radio>
                            <Radio value={3}>ABCABCABC</Radio>
                        </Radio.Group>

                    </Form.Item>
                    {/* <Form.Item
                        className="notFlex"
                        label="使用弹窗授权获取unionld"
                        valuePropName="checked"
                        labelCol={{ span: 4 }}
                        name="getunionid"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}
                    >
                        <Switch checkedChildren="开" unCheckedChildren="关" onChange={handleSwictch('getunionid')} className="unionLClass" style={{ position: 'relative', width: '37px' }} />
                    </Form.Item> */}
                    <Form.Item
                        label="前端模板"
                        name="templated"
                        className="notFlex"
                        rules={[
                            {
                                required: true,
                                message: '请选择分类',
                            },
                        ]}

                    >
                        <Radio.Group onChange={handleTemplate}>
                            {
                                originType == 1 ? <Fragment>
                                    <Radio value={0}>无</Radio>
                                    <Radio value={1}>模板1</Radio>
                                    <Radio value={2}>模板2</Radio>
                                </Fragment> : originType == 2 ? <Fragment>
                                    <Radio value={0}>无</Radio>
                                    <Radio value={1}>模板1</Radio>
                                </Fragment> : <Fragment>
                                    <Radio value={0}>无</Radio>
                                </Fragment>
                            }

                        </Radio.Group>
                    </Form.Item>
                    {
                        originType == 1 && templateTypes == 1 ? (
                            <Fragment>
                                <div className="templateOne">
                                    <div style={{ position: 'relative' }}>
                                        <img src={TemplateOne}></img>
                                        <div className="RedCodeContent">
                                            <div className="header">
                                                <div className="leftAvatar">
                                                    <img src={Avatar16} alt="" style={{ borderRadius: '50%', width: '100%' }} />
                                                </div>
                                                <div className="rightName">
                                                    <div className="teacherName">{RedCodeTitle.title ? RedCodeTitle.title : 'ACCA学习老师'}</div>
                                                    <div className="rightBottomNumber">
                                                        <img src={share} alt="" /><span style={{ marginLeft: '8px' }}>已有<span> {RedCodeTitle.initNumber ? RedCodeTitle.initNumber : 1200}</span>人添加成为了好友！</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {/*  */}
                                            <div className="showTimes" style={{ display: RedCodeTitle.show ? 'block' : 'none' }}>
                                                剩余时间<span>23</span>:<span>35</span>:<span>16</span>
                                            </div>
                                            <div className="showSuccess" style={{ marginTop: RedCodeTitle.show ? '0px' : '30px' }}>
                                                <img src={success} style={{ width: '16px', height: '16px' }} alt="" /><span>此二维码已通过安全认证,可放心扫码</span>
                                            </div>
                                            <div className="codeRed">
                                                <img src={RedCode} alt="" />
                                                <div className="Code" >
                                                    <QRCode value={'https://www.gaodun.com/'} size={178} renderAs='canvas' level='H' />,
                                                </div>

                                            </div>
                                            <div className="redBottom">
                                                {RedCodeTitle.info ? RedCodeTitle.info : '添加老师，体验全课程学习ACCA知识'}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="rightContent" style={{ marginLeft: '50px' }}>

                                        <Form.Item
                                            label='头像'
                                            name="Avatar"
                                            labelCol={{
                                                span: 8
                                            }}
                                            valuePropName="fileList"
                                            getValueFromEvent={normFile}
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 1 ? true : false,
                                                    message: '请上传图片',
                                                },
                                            ]}
                                        >
                                            <Upload
                                                id='uploadOne'
                                                data-type='0'
                                                name="file"
                                                data={{
                                                    savePath: 'LiveCode'
                                                }}
                                                listType="picture-card"
                                                className="avatar-uploader"
                                                showUploadList={false}
                                                headers={
                                                    {
                                                        token: store.getState().toJS().global.token
                                                    }
                                                }
                                                action={`${baseUrl}admin/uploads`}
                                                beforeUpload={beforeUpload}
                                                onChange={handleChange(uploadItems.uploadTemplate)}
                                            >
                                                {uploadTemplateAvatar.imageUrl ? <img src={uploadTemplateAvatar.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                                            </Upload>
                                        </Form.Item>
                                        <Form.Item
                                            label='名称'
                                            name="temlateOneName"
                                            labelCol={{
                                                span: 8
                                            }}
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 1 ? true : false,
                                                    message: '请填写名称',
                                                },
                                            ]}
                                        >
                                            <Input onChange={(e) => { handleInputRedCode(e.target, 'title') }}></Input>
                                        </Form.Item>
                                        <Form.Item
                                            label='初始显示添加人数'
                                            name="initShow"
                                            labelCol={{
                                                span: 8
                                            }}
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 1 ? true : false,
                                                    message: '请填写名称',
                                                },
                                                {
                                                    max: 9999,
                                                    message: '上限9999'
                                                }
                                            ]}
                                        >
                                            <Input type="number" onChange={(e) => { handleInputRedCode(e.target, 'initNumber') }}></Input>
                                        </Form.Item>
                                        <Form.Item
                                            valuePropName="checked"
                                            label='显示时间'
                                            name="showTime"
                                            labelCol={{
                                                span: 8
                                            }}
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 1 ? true : false,
                                                    message: '请填写名称',
                                                },
                                            ]}
                                        >
                                            <Switch defaultChecked onChange={(e) => { handleInputRedCode(e, 'show') }} />

                                        </Form.Item>

                                        <Form.Item
                                            label=''
                                            name="time"
                                            style={{ marginLeft: '135px' }}
                                            labelCol={{
                                                span: 8
                                            }}
                                        >
                                            <DatePicker disabledDate={disabledDate} showTime />
                                        </Form.Item>



                                        <Form.Item
                                            label='介绍文字'
                                            labelCol={{
                                                span: 8
                                            }}
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 1 ? true : false,
                                                    message: '请填写名称',
                                                },
                                            ]}
                                            name="info">
                                            <TextArea
                                                onChange={(e) => { handleInputRedCode(e.target, 'info') }}

                                                placeholder="请填写介绍信息"
                                                autoSize={{ minRows: 3, maxRows: 5 }}
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                            </Fragment>
                        ) : originType == 1 && templateTypes == 2 ? (
                            <Fragment>
                                <div className="templateOne">
                                    <div style={{ position: 'relative' }}>
                                        <div className="titleTemplate">
                                            {titleCode.title}

                                        </div>
                                        <img src={TemplateTwo} style={{ width: '375px', height: '667px' }} />
                                        <div className="titleTop">{titleCode.topTitle}</div>
                                        <div className="bottomTitle">
                                            <div>{titleCode.bottomTitleTop}</div>
                                            <div>{titleCode.bottomTItleBottom}</div>
                                        </div>
                                    </div>
                                    {/* style={{marginLeft:'50px'}} */}
                                    <div style={{ width: '500px', display: 'flex', justifyContent: 'center', flexDirection: 'column', marginLeft: '50px' }}>
                                        <Form.Item

                                            label='标题'
                                            name="templateTitle"
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 2 ? true : false,
                                                    message: '请填写名称',
                                                },
                                                {
                                                    max: 15,
                                                    message: '15字以内'
                                                }
                                            ]}
                                            labelCol={{
                                                span: 8
                                            }}>

                                            <Input onChange={(e) => { handleInput(e.target, 'title') }} />
                                        </Form.Item>
                                        <Form.Item

                                            label='二维码上方描述'
                                            name="templateTitleTop"
                                            rules={[
                                                {
                                                    required: originType == 1 && templateTypes == 2 ? true : false,
                                                    message: '请填写名称',
                                                },
                                                {
                                                    max: 15,
                                                    message: '15字以内'
                                                }
                                            ]}
                                            labelCol={{
                                                span: 8
                                            }}>
                                            <Input onChange={(e) => { handleInput(e.target, 'topTitle') }} />
                                        </Form.Item>
                                        <Form.Item

                                            label='二维码下方描述'
                                            name="templateTitleBottom"
                                            rules={[

                                                {
                                                    required: originType == 1 && templateTypes == 2 ? true : false,
                                                    message: '请填写名称',
                                                },
                                                {
                                                    max: 30,
                                                    message: '30字以内'
                                                },
                                            ]}
                                            labelCol={{
                                                span: 8
                                            }}>
                                            <Input onChange={(e) => { handleInput(e.target, '') }} />
                                        </Form.Item>
                                    </div>
                                </div>


                            </Fragment>
                        ) : originType == 2 && templateTypes == 1 ? <Fragment>
                            <div className="templateOne">
                                <div style={{ position: 'relative' }}>
                                    <img src={TemplateThree}></img>
                                    <div className="TwoTilte">
                                        <span>

                                            {lanCodeTitle.title ? lanCodeTitle.title : '高效冲刺CPA考试'}
                                        </span>
                                    </div>
                                    <div className="topNumber">
                                        <div> 已有<span style={{ color: '#3838FD', fontSize: '23px' }}>{lanCodeTitle.initNumber}</span>人群,快来寻找小伙伴 </div>
                                    </div>
                                    <div className="photosImg">
                                        {RandomImg.map((v, i) => {
                                            return (
                                                <img
                                                    src={v}
                                                    key={i}
                                                    style={{ width: '30px', height: '30px' }}
                                                    style={{ marginRight: "6px" }}
                                                ></img>
                                            );
                                        })}
                                        <div className="aroundPhoto">...</div>
                                    </div>
                                    <div className="vermiddelBorder">

                                    </div>
                                    <div className="showTime">
                                        <div className="time" style={{ display: lanCodeTitle.show ? 'block' : "none" }}>
                                            剩余时间<span>23</span>:<span>35</span>:<span>16</span>
                                        </div>
                                        <div className="showSuccess" style={{ marginTop: lanCodeTitle.show ? '0px' : '30px' }}>
                                            <img src={success} style={{ width: '16px', height: '16px' }} alt="" /><span>此二维码已通过安全认证,可放心扫码</span>
                                        </div>
                                        <div className="CodeLan">
                                            <img src={lanCode} alt="" />
                                            <div className="Code">
                                                <QRCode value={'https://www.gaodun.com/'} size={178} renderAs='canvas' level='H' />,
                                            </div>
                                        </div>
                                        <div className="bottomCode">
                                            {lanCodeTitle.info ? lanCodeTitle.info : ' 扫码进群,领取价值4999大礼包'}
                                        </div>
                                    </div>
                                    {/* <div className="photos">
                                          
                                        </div> */}
                                </div>

                                <div className="rightContent" style={{ width: '500px', marginLeft: '164px' }}>


                                    <Form.Item
                                        label='名称'
                                        name="temlateOneName"
                                        labelCol={{
                                            span: 8
                                        }}
                                        rules={[
                                            {
                                                required: originType == 1 && templateTypes == 1 ? true : false,
                                                message: '请填写名称',
                                            },
                                            {
                                                max: 15,
                                                message: '15字以内'
                                            }
                                        ]}
                                    >
                                        <Input onChange={(e) => { handleInputLanCode(e.target, 'title') }}></Input>
                                    </Form.Item>
                                    <Form.Item
                                        label='初始显示添加人数'
                                        name="initShow"
                                        labelCol={{
                                            span: 8
                                        }}
                                        rules={[
                                            {
                                                required: originType == 1 && templateTypes == 1 ? true : false,
                                                message: '请填写名称',
                                            }, {
                                                max: 10000,
                                                message: '上限10000'
                                            }
                                        ]}
                                    >
                                        <Input type="number" onChange={(e) => { handleInputLanCode(e.target, 'initNumber') }}></Input>
                                    </Form.Item>
                                    <Form.Item
                                        valuePropName="checked"
                                        label='显示时间'
                                        name="showTime"
                                        labelCol={{
                                            span: 8
                                        }}
                                        rules={[
                                            {
                                                required: originType == 2 && templateTypes == 1 ? true : false,
                                                message: '请填写名称',
                                            },
                                        ]}
                                    >
                                        <Switch defaultChecked onChange={(e) => { handleInputLanCode(e, 'show') }} />

                                    </Form.Item>

                                    <Form.Item
                                        label=''
                                        name="time"
                                        style={{ marginLeft: '164px' }}
                                        labelCol={{
                                            span: 8
                                        }}
                                    >
                                        <DatePicker disabledDate={disabledDate} showTime />
                                    </Form.Item>
                                    <Form.Item
                                        label='介绍文字'
                                        labelCol={{
                                            span: 8
                                        }}
                                        rules={[
                                            {
                                                required: originType == 1 && templateTypes == 1 ? true : false,
                                                message: '请填写名称',
                                            },
                                            {
                                                max: 30,
                                                message: '30字以内'
                                            }
                                        ]}
                                        name="info">
                                        <TextArea
                                            onChange={(e) => { handleInputLanCode(e.target, 'info') }}
                                            placeholder="请填写介绍信息"
                                            autoSize={{ minRows: 3, maxRows: 5 }}
                                        />
                                    </Form.Item>
                                </div>
                            </div>
                        </Fragment> : (
                            <Fragment>

                            </Fragment>
                        )

                    }
                </div>


                <div style={{ marginTop: '20px', backgroundColor: '#ffffff', padding: "20px", display: isEdit ? 'none' : 'block' }}>
                    <Form.Item
                        label="排班人员"
                        name="schedulingRuncutting"
                        className="flexEdit"
                        rules={[
                            {
                                required: false,
                                message: '请添加人员',
                            },
                        ]}
                    >
                        <Form.Item
                        >
                            <Button size='smart' onClick={incrementInfo}>+添加成员</Button>
                            <Form.Item>
                                <Spin spinning={deleteLoading}>
                                    <Draggables drwerList={drwerList} drawerEndEvent={drawerEndHandle} deleteDrawerInfo={Deluece(deleteDrawerInfo, 200)}></Draggables>
                                </Spin>
                            </Form.Item>
                        </Form.Item>

                    </Form.Item>
                    <Form.Item
                        label="员工添加上限"
                        name="notlimit"
                        className="flexEdit"
                        wrapperCol={{
                            span: 24
                        }}
                    ><Form.Item>

                            <div className="content-message">
                                员工添加上限：员工从该渠道添加的客户达到上限后，将自动下线，不在接待该渠道新客户。
                                若上限设为0，则所有个人码均不设置上限
                            </div>
                            <Form.Item>
                                {/*  */}
                                <Input style={{ width: '300px' }} name="schedulingRuncutting" placeholder="请输入工号或姓名"
                                    onChange={(e) => { handleupperLimit(e.target) }}

                                />
                                <span style={{ marginLeft: '40px', marginBottom: '20px' }} onClick={batchAllUpdate}>批量设置</span>
                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <div style={{ marginTop: '20px' }}>
                                    <Table
                                        loading={loadingTime}
                                        rowSelection={{
                                            type: selectionType,
                                            ...rowSelection,
                                        }}
                                        pagination={false}
                                        columns={columns}
                                        dataSource={staff}
                                    />
                                </div>
                            </Form.Item>
                        </Form.Item>


                    </Form.Item>
                    <Divider dashed />
                    <Form.Item wrapperCol={{ span: 24 }} className="flexEdit">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Item>
                                <Radio.Group onChange={handlebtnFilter('isstatus')} defaultValue={'0'}>
                                    <Radio.Button value="0">全部</Radio.Button>
                                    <Radio.Button value="1">结束</Radio.Button>
                                    <Radio.Button value="2">使用中</Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item>  <span style={{ marginLeft: "40px", marginRight: '40px' }}>发布时段</span></Form.Item>
                            <Form.Item> <Space direction="vertical" size={12}>
                                <RangePicker
                                    format="YYYY-MM-DD"
                                    onChange={handlebtnFilter('time')}
                                    onOk={onOk}
                                />
                            </Space></Form.Item>
                            <Form.Item>  <span style={{ marginLeft: "30px", marginRight: '20px' }}><Input onBlur={SearchaBlue('searchGroupId')} onPressEnter={SearchaBlue('searchGroupId')} placeholder="请输入工号" /></span></Form.Item>
                            <Form.Item>  <span style={{ marginLeft: "60px", marginRight: '40px' }}><Input onBlur={SearchaBlue('searchCommnet')} onPressEnter={SearchaBlue('searchCommnet')} placeholder="请输入备注" /></span></Form.Item>

                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <Table columns={columnsTwo} loading={loadingAll} dataSource={paramds} pagination={false}></Table>
                            <div className="pagetions">
                                <Pagination
                                    total={countTableTotal}
                                    showSizeChanger
                                    onChange={handlePageChange}
                                    showQuickJumper
                                    current={pagtionall.page}
                                    showTotal={() => `共 ${paramds.length} 条`}
                                />
                            </div>

                        </div>
                    </Form.Item>

                </div>
                <div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', backgroundColor: '#ffffff', padding: "20px", }}>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                        <Button style={{ marginLeft: '20px' }} onClick={handleReset}>
                            取消
                        </Button>
                    </div>
                </div>
            </Form>
            <ModelInfo width={400} isEnable={isEnable} title={title} CanValOpenClose={CanValOpenCloseAll} setIsModalVisible={setIsModalVisibleLimit} children={<div className="batch" style={{ textAlign: 'center' }}>
                <span style={{ marginRight: '20px' }}>客户上线:</span><InputNumber min={1} max={1000} onChange={handleMaxValue}></InputNumber>
            </div>}>
            </ModelInfo>
            {/* footer={originType == 0 ? null : true} */}
            <Modal title="" width={669} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} >
                {originType == 0 ? <div>sadasds</div> : (
                    (<div>
                        <div style={{ textAlign: 'center', fontSize: '18px', marginBottom: '10px' }}>{originType == 1 ? '添加个人码' : '添加成员'}</div>
                        <div style={{ width: '300px', position: 'relative' }}>这里添加的是企业微信群码若是没有找到合适的二维码,
                            <span style={{ color: '#D9001B', position: 'absolute', top: '22px' }} onClick={openGroupEnable}>手动添加</span></div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Input size="default" placeholder="请输入姓名或工号" style={{ width: '250px', margin: '20px 0px  5px' }} onChange={(e) => { searchGroupName(e.target) }} prefix={<SearchOutlined />} />
                        </div>
                        <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                已选成员({notSelect.length})
                            </div></div>

                        <div className="addGroupGroid">
                            <div className="leftSum" id="scrollItems">
                                {
                                    items.length ? items.map((item, i) => {
                                        return (
                                            <div className="leftAllGroup" key={i}>
                                                <span>
                                                    <img width="35" height='35' style={{ borderRadius: '5px' }} src={item.url} alt="" />
                                                </span>
                                                <span style={{ textAlign: 'center' }}>{item.content}</span>
                                                <span><Radio defaultChecked={false} checked={item.ischeck ? true : false} onChange={handleSelectCancle(item)}></Radio></span>
                                            </div>
                                        )
                                    }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }


                            </div>
                            <div className="rightSum">
                                {
                                    notSelect.length ? notSelect.map((v, i) => {
                                        return (
                                            <div className="rightSelectGroup" key={i}>
                                                <span>
                                                    <img width="35" height='35' style={{ borderRadius: '5px' }} src={v.url} alt="" />
                                                </span>
                                                <span style={{ textAlign: 'center' }}>{v.content}</span>
                                                <span><CloseOutlined onClick={deletSelectInfo(v)} /></span>
                                            </div>
                                        )
                                    }) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                }

                            </div>
                        </div>

                    </div>)
                )}


            </Modal>
            <ModelInfo width={400} isEnable={isGroupModel} title={''} CanValOpenClose={CanValGroupModelClose} setIsModalVisible={setGroupModel} children={
                (
                    <div>
                        <Form
                            {...layoutGroupCode}
                            preserve={false}
                            name="basic"
                            preserve={false}
                            initialValues={{ remember: true }}

                        >
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label='个人码'
                                name="infoCode"
                                rules={[{ required: true, message: '请上传图片!' }]}
                            >
                            </Form.Item>


                        </Form>

                    </div>
                )
            }>

            </ModelInfo>
            <ModelInfo
                CanValOpenClose={CanValOpenCloseModelInfo}
                isEnable={isGroupModelInfo}
                width={500}
                style={{ textAlign: 'center' }}
                title={originType == 1 ? '添加个人码信息' : (originType == 2 ? '添加微信群码' : '')}
                setIsModalVisible={handleManualClose}
                iscenter={true}
                isShowFoot={null}

            >
                <Form
                    {...layoutGroupCode}
                    name="basic"
                    preserve={false}
                    form={formUploadInfo}
                    preserve={false}
                    onFinish={onFinishAddGroup}
                    onFinishFailed={onFinishFailedGroup}
                >
                    <Form.Item
                        label="名称"
                        name="username"
                        rules={[{ required: true, message: '请输入你的名称!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{ display: originType == 2 ? 'none' : 'flex' }}
                        label="工号"
                        name="NationalityID"
                        rules={[{ required: originType == 2 ? false : true, message: '请输入你的学号!' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="头像"
                        name="Avatar"
                        labelCol={{ span: 5 }}
                        wrapperCol={{
                            span: 8
                        }}
                        style={{ display: originType == 2 ? 'none' : 'flex' }}
                        rules={[{ required: originType == 2 ? false : true, message: '请上传头像!' }]}
                    >
                        <div>
                            <Upload
                                id='uploadOne'
                                data-type='0'
                                name="file"
                                data={{
                                    savePath: 'LiveCode'
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                headers={
                                    {
                                        token: store.getState().toJS().global.token
                                    }
                                }
                                action={`${baseUrl}admin/uploads`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange(uploadItems.uploadAvatar)}
                            >
                                {uploadAvatar.imageUrl ? <img src={uploadAvatar.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item
                        label="个人码"
                        name="infoCodeGroup"
                        labelCol={{ span: 5 }}
                        wrapperCol={{
                            span: 8
                        }}
                        rules={[{ required: true, message: '请上传个人码!' }]}
                    >
                        <div>
                            <Upload
                                id='uploadOne'
                                data-type='0'
                                name="file"
                                data={{
                                    savePath: 'LiveCode'
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                headers={
                                    {
                                        token: store.getState().toJS().global.token
                                    }
                                }
                                action={`${baseUrl}admin/uploads`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange(uploadItems.uploadCode)}
                            >
                                {uploadInfo.imageUrl ? <img src={uploadInfo.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                            </Upload>
                        </div>
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            span: 24
                        }}
                        style={{ marginLeft: "auto" }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type="primary" htmlType="submit" style={{ marginRight: '20px' }}>
                                确定
                            </Button>
                            <Button type="default" onClick={OriginTypeTwoHandle}>
                                取消
                            </Button>
                        </div>
                    </Form.Item>
                </Form>

            </ModelInfo>
            {/*                 setIsModalVisible={handleManualClose}
            // isEnable={isGroupModelInfo}
 */}
            <ModelInfo
                width={500}
                iscenter={true}
                isShowFoot={null}
                isEnable={isOneInfo}
                CanValOpenClose={CanValOpenCloseOrigin}
                setIsModalVisible={CloseOriginTypeOne}
                style={{ textAlign: 'center' }}>
                <div>
                    <div style={{
                        margin: '20px', textAlign: 'center',
                        fontSize: '24px'
                    }}>
                        添加APP码信息</div>
                    {/*                        */}
                    <Form
                        {...layout}
                        name="fomeInfo"
                        preserve={false}
                        form={codeInfoIncrement}
                        onFinish={onFinsishgetApp}
                        onFinishFailed={onFiledInfo}
                    >
                        <Form.Item
                            label="姓名"
                            labelAlign={'left'}
                            name="infoCodeUserName"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的姓名!',

                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="微信号"
                            name="wxName"
                            labelAlign={'left'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的微信号!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        {/* */}
                        <Form.Item
                            label="CRMID"
                            name="CRMID"

                            labelAlign={'left'}
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的CRMID!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="头像"
                            labelAlign={'left'}
                            name="portrait"
                            wrapperCol={
                                { span: 6 }
                            }
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的头像!',
                                },
                            ]}
                        >
                            <Upload
                                name="file"
                                data={{
                                    savePath: 'LiveCode'
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                headers={
                                    {
                                        token: store.getState().toJS().global.token
                                    }
                                }
                                action={`${baseUrl}admin/uploads`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange(uploadItems.uploadAvatar)}
                            >
                                {uploadAvatar.imageUrl ? <img src={uploadAvatar.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                            </Upload>
                        </Form.Item>
                        <Form.Item
                            label="个人码"
                            name="infoCodeUrl"
                            labelAlign={'left'}
                            wrapperCol={
                                { span: 6 }
                            }
                            rules={[
                                {
                                    required: true,
                                    message: '请输入你的个人码!',
                                },
                            ]}
                        >
                            <Upload
                                name="file"
                                data={{
                                    savePath: 'LiveCode'
                                }}
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                headers={
                                    {
                                        token: store.getState().toJS().global.token
                                    }
                                }
                                action={`${baseUrl}admin/uploads`}
                                beforeUpload={beforeUpload}
                                onChange={handleChange(uploadItems.uploadThreeCode)}
                            >
                                {uploadAvatarNext.imageUrl ? <img src={uploadAvatarNext.imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton()}
                            </Upload>
                        </Form.Item>
                        <Form.Item wrapperCol={{ span: 20 }} >
                            <div style={{ display: 'flex' }}>
                                <Button type="primary" htmlType="submit" style={{ marginRight: '20px', marginLeft: '74%' }}>
                                    确认
                                </Button>
                                <Button type="default" onClick={closeMo}>
                                    取消
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </ModelInfo>

        </div>
    )
}
