/*
 * @Author: your name
 * @Date: 2021-06-03 11:48:59
 * @LastEditTime: 2021-08-11 12:58:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \livecode\src\index.js
 */

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux"
import store from "./store";
import { ConfigProvider } from 'antd';
// import zhCN from 'antd/lib/locale/zh_CN';
import './index.less';
import 'braft-editor/dist/index.css';
import "./resource/css/reset.less"
import App from './LiveCodeModules/Containers/App.js';
import axios from "./request/index"
import 'rsuite/lib/styles/index.less';
import zhCN from "antd/lib/locale/zh_CN";
import ModelProvider from '../src/Provider/index'
import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
window.$axios = axios;
ReactDOM.render(
    <ConfigProvider locale={zhCN}>
      <Provider store={store}>
        <ModelProvider>
           <App/>
        </ModelProvider>
      </Provider>,
    </ConfigProvider>,
    document.getElementById('root')
);

