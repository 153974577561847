import {fromJS} from "immutable";
import cookies from 'react-cookies'
import {SET_TOKEN, CLEAR_TOKEN, SET_BREADCRUMB, SET_ACTIVETABKEY, GET_CONFIG} from "../../actionTypes";

const breadcrumb = {
  banner: [
    {
      name: "Banner列表",
      path: "/Main/BannerList",
    }
  ],
  user: [
    {
      name: "学员管理",
      path: "/Main/UserList",
    }
  ],
  feedback: [
    {
      name: "反馈列表",
      path: "/Main/Feedback",
    }
  ],
  video: [
    {
      name: "视频管理列表",
      path: "/Main/VideoList",
    }
  ],
  course: [
    {
      name: "课程管理",
      path: "/Main/Course",
    }
  ],
  english: {
    "1": [
      {
        name: "分级词汇",
        path: "/Main/English/1",
      }],
    "2": [
      {
        name: "每日一句",
        path: "/Main/English/2",
      }]
  },
  politics: {
    "1": [
      {
        name: "选择题精练/押题点睛",
        path: "/Main/Politics/1",
      }],
    "2": [
      {
        name: "分析题领背",
        path: "/Main/Politics/2",
      }]
  }
};

let initialState = {
  token: cookies.load("token") || "",
  account: "",
  activeBreadcrumb: [],
  activeTabKey: "1",
  config: {
    vocabularyDay: [],
    politicsDay: [],
    english_level: [],
    list_indentity: [],
    video_typeId: {}
  },
  loading: false
};

const baseBreadcrumb = {
  name: "首页",
  path: "/Main/LoginSuccess",
};

export function global(state = fromJS(initialState), action) {
  if (action.type === SET_TOKEN) {
    const expires = new Date();
    expires.setDate(Date.now() + 1000 * 60 * 60 * 24);
    cookies.save(
        "token",
        action.token,
        {
          path: '/',
          expires,
          domain: null
        }
    );
    return state.set('token', action.token)
  }

  if (action.type === CLEAR_TOKEN) {
    cookies.remove("token");
    return state.set("token", "")
  }

  if (action.type === SET_BREADCRUMB) {
    return state.set('activeBreadcrumb', breadcrumb[action.key].length === undefined ? [baseBreadcrumb].concat(breadcrumb[action.key][state.get("activeTabKey")]) : [baseBreadcrumb].concat(breadcrumb[action.key]))
  }

  if (action.type === SET_ACTIVETABKEY) {
    return state.set("activeTabKey", action.activeIndex)
  }

  if (action.type === GET_CONFIG) {
    let tempConfig = action.config;
    let config = {};
    config.vocabularyDay = tempConfig.day.filter(item => item.type === 1)[0].list.map(item => ({
      value: item,
      label: 'day' + item
    }));
    config.politicsDay = tempConfig.day.filter(item => item.type === 2)[0].list.map(item => ({
      value: item,
      label: 'day' + item
    }));
    config.english_level = tempConfig.english_level.map(item => ({
      value: item.id,
      label: item.level
    }));
    config.list_indentity = tempConfig.list_indentity.map(item => ({
      value: item.id,
      label: item.indentity
    }));


    let video_types = {};
    Object.keys(tempConfig.video_types).forEach(item => {
      video_types[item] = Object.keys(tempConfig.video_types[item]).map(_item => {
        return {
          value: _item * 1,
          label: tempConfig.video_types[item][_item]
        }
      })
    });

    config.video_typeId = video_types;

    return state.set("config", config)
  }
  return state
}
