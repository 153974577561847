/*
 * @Author: your name
 * @Date: 2021-06-03 11:48:59
 * @LastEditTime: 2021-08-11 09:03:45
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \livecode\src\common\Main\InnerRouters.js
 */
import { lazy, Suspense, useEffect } from 'react'
import { Spin } from 'antd';
import { connect } from 'react-redux'
import { HashRouter as Router, Route, Redirect } from "react-router-dom"
import React from "react";
import Loadings from '../loading'
import { getMenuRouter } from '../../store/actionCreator/global'
import { compose } from 'redux';
import LiveCodeInfo from '../../LiveCodeModules/Containers/LiveCodeDetail/index.jsx'
import liveCodeEdit from '../../LiveCodeModules/Containers/LiveCodeDetail/LiveCodePersonal/index.jsx'
const _import = path => lazy(() => import(`../../${path}/index`))
function gentanterTree(element) {
  const newRouters = element.map((v,i) => {
      let router={
        path:v.path,
        component:_import(v.component)
      }
      if(v.children&&router.children.length>0){
        router.children=gentanterTree(router.children)
      }
      return router;
  })
  return newRouters
}

function innerRouters(props) {
  const routers=gentanterTree(props.leftMenuRouters) ;
  console.log(routers)
  return (
    <div className="routers">
      <Router>
        <Suspense fallback={<Loadings />}>
          {
            routers.map((v,i)=>{
              return (
                <Route {...v} key={v.path} ></Route>
                // <Route path={v.path} component={v.component} key={i}/>
              )
            })
          }
        </Suspense>

      </Router>
    </div>
  )
}
const mapStateToProps = (state) => ({ ...state.toJS().MenuLeft })
const mapDispatchToProps = () => ({
  getMenuRouter
})
export default connect(mapStateToProps, mapDispatchToProps)(innerRouters);