import React,{useState} from 'react'
import { Modal, Button } from 'antd';

export default function Index(props) {
    return (
        <Modal destroyOnClose style={{ textAlign:props.iscenter?'center':'left' }} 
           width={props.width?props.width:800}   footer={props.isShowFoot} title={props.title} visible={props.isEnable} 
           onOk={()=>{props.setIsModalVisible(props)}}
           onCancel={()=>{props.CanValOpenClose(props)}}
        >
              {props.children}
      </Modal>
    )
} 
