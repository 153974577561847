import React, { createContext, useState, useEffect } from 'react'
import { Form, message } from 'antd'
import * as moment from 'moment'
import { get, post, postHttp } from "../request/axios";
import store from "../store";
export const ModelFormContent = createContext();
export default function Index({ children }) {
    const [form] = Form.useForm();
    const [preProject, setpreProject] = useState([]);//项目列表信息
    const [searchCaptche, setSearchCaptche] = useState([{ name: "全部", value: 0, }, { name: "关闭", value: 1 }, { name: "开启", value: 2 }])
    const [ModalInfo, setModalInfo] = useState({
        title: '新建项目',
        isModalVisible: false,
        handleOk: () => { form && form.submit(); },
        onCancel: () => { form && form.resetFields(); setModalInfo({ ...ModalInfo, isModalVisible: false }) },
        confirmLoading: false
    })
    const FormDate = (time) => {
        return moment(time).format('YYYY-MM-DD hh:mm:ss')
    }
    const MapReplaceKey = (result) => {
        return result?.length ? result.map((v) => {
            return {
                ...v,
                key: v.id + ''
            }
        }) : []
    }
    const initProjectList = async () => {
         try {
            let { data: { data: { result: projectList } } } = await get('admin/recall/project/list', { page: 1, rows: 1000, enable: 2 });
            return projectList.map((v) => {
                   return {
                       value: v.id,
                       name: v.project_name
                   }
               })
         } catch (error) {
            return []
         }

    }
    const ScrollUp = {
        height: 40,
        width: 40,
        lineHeight: '40px',
        borderRadius: 4,
        backgroundColor: '#1088e9',
        color: '#fff',
        textAlign: 'center',
        fontSize: 14,
    };
    const AuthModel = {
        ModalInfo,
        setModalInfo,
        form,
        FormDate,
        searchCaptche,
        MapReplaceKey,
        // preProject,
        initProjectList,
        ScrollUp
    }

    return (
        <ModelFormContent.Provider value={{ ...AuthModel }}>
            {children}
        </ModelFormContent.Provider>
    )
}
