import axios from "axios";
import {createHashHistory} from 'history';
import store from "../store";
import {message} from "antd";
import {getDataType} from "../utils";
import Qs from 'qs'
let isedit=false
class HttpRequest {
  interceptors(instance) {
    instance.interceptors.request.use(function (config) {
      // 在发送请求之前做些什么
      const token = store.getState().toJS().global.token;
      token && (config.headers["token"] = token);
      return config;
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    });

    // 添加响应拦截器
    instance.interceptors.response.use(function (response) {
      let {code, info} = response.data;
      if (code !== 200) {
        message.error({
          content: info
        });
        const errCode = [40002, 40001, 202];
        if (errCode.indexOf(code) > -1) {
          /**40002登录超时，重新登录*/
          setTimeout(function () {
            const history = createHashHistory();
            history.push('/');
          }, 300);
        }
      } else if (code === 200) {
        return Promise.resolve(response.data);
      }
      return Promise.resolve(response);
    }, function (error) {
      /**服务器状态码不是200的情况*/
      message.error({
        content: `请求错误-${error}`
      });
      return Promise.reject(error.response);
    });
  };

  request(option) {
    const {url, method = 'get', data} = option;
    let instance = axios.create();
    let config = {
      url,
      method: method.toLowerCase()
    };
    if (config.method === "put" || config.method === "post" || config.method === "patch") {
      data && (config["data"] = data);
    } else {
      data && (config["params"] = filterData(data));
    }
    instance.defaults.timeout = 60000;
    this.interceptors(instance);
    return instance(config)
  }
}

const filterData = (data) => {
  let rData = {};
  for (let prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (((getDataType(data[prop]) === "string" || getDataType(data[prop]) === "number") && data[prop] !== "") || ((getDataType(data[prop]) === 'array') && data[prop].length)) {
        rData[prop] = data[prop];
      }
    }
  }
  return rData
};

export const baseUrl=''
// export const baseUrl=''
const  http =axios.create({
  baseURL:baseUrl,
  timeout:10000,

})
 http.interceptors.request.use(function (config) {
  const token = store.getState().toJS().global.token;
  localStorage.setItem('token',token)
  if(token){
    config.headers={
      'token':token,
     'Content-type':'application/json'
    }
  }
  // token && (config.headers["token"] = token);
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
http.interceptors.response.use(function (response) {
  let {code, info} = response.data;
  if (code !== '000000') {
    if(code==300000||code==100000){
      message.error({
        content: info
      });
      setTimeout(function () {
        const history = createHashHistory();
        history.push('/');
      }, 300);
    }else{
      message.error({
        content: info
      });
    }
 
  
    
  } else if (code === 200) {
    return Promise.resolve(response.data);
  }
  return Promise.resolve(response);
}, function (error) {
  /**服务器状态码不是200的情况*/
  message.error({
    content: `请求错误-${error}`
  });
  return Promise.reject(error.response);
});

export const post=(url,data)=>{
  isedit=true
  return http.post(url,data)
}

export const postHttp=(url,data)=>{
  isedit=false
  return http.post(url,data)
}
export const get=(url,params)=>{
  isedit=true
  return http.get(url,{
    params:params
  })
}
const labelInstance=axios.create({
  baseURL:'',
})
export const  LabelGet=(url,params)=>{
  let hostUrl=window.location.href;
  return labelInstance({
    url:`https://${(hostUrl.indexOf('dev')!=-1||hostUrl.indexOf('localhost')!=-1)?'dev-java.pxo.cn/5270':'adm-tag.pxo.cn'}/${url}`,
    method:"get",
    params
  })
}
export const labelPost=(url,data)=>{
  isedit=false
  return http.post(url, data)
   
}


export default HttpRequest;
