import React, { Component } from 'react'
import './index.less'
import { Button, Radio, RadioGroup, Popover, Input, Select, Table, Tag, Space, Switch, Pagination, message, Modal, Cascader } from 'antd';
import { status, types } from './comm'
import { InfoCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { NavLink, withRouter } from 'react-router-dom';
import http from '../../../request/index';
import QRCode from 'qrcode.react';
import ModelInfo from '../../../common/Modle/index'
import copy from 'copy-to-clipboard';
import { get, post } from '../../../request/axios'
import * as momnet from 'moment'
// import { Cascader } from 'rsuite';

// var dayjs = require('dayjs')
const { confirm } = Modal;

const { Option } = Select;
class Index extends Component {
    state = {
        DefaultTypeIds: [],
        isAuthot: false,
        loading: false,
        options: [

        ],
        SearchTitle: {
            isModel: false,//是否显示Model
            isstatus: 0,
            type: 0,
            originType: 0,
            searchName: '',
            CodeUrl: ''

        },

        selectedRowKeys: [],
        pagetion: {
            total: 0,
            page: 1,
            pageSize: 10,

        },
        list: [

        ],
        size: 1,

    }
    /**
     * 
     */
    columns = [
        {
            width: "70px",
            title: '编号',
            dataIndex: 'key',
            key: 'key',
            render: text => <a>{text}</a>,
        },
        {
            width: "100px",
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: name => <span style={{ width: "100px" }}>{name}</span>,
        },
        {
            title: '分类',
            dataIndex: 'typename',
            key: 'typename',
        },
        {
            title: '个人码使用情况',
            dataIndex: 'sum',
            key: 'sum',
            render: sum => (
                <>
                    {sum.map((items, i) => {
                        return (
                            <div style={{ width: "100px" }} key={i}>
                                <Tag color={i === 0 ? 'processing' : (i === 1) ? 'success' : 'error'}>
                                    <span>{i === 0 ? '总数量' : i === 2 ? '已关闭' : '使用中'}{sum[i]}</span>
                                </Tag>
                            </div>
                        );
                    })}
                </>
            ),
        },
        {
            title: '总分配人数',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: '未扫码分配',
            dataIndex: 'notEwm',
            key: 'notEwm',
        },
        {
            title: '进入页面总人数',
            dataIndex: 'pagetotal',
            key: 'pagetotal',
        },
        {
            title: '累计长按',
            dataIndex: 'longtiMe',
            key: 'longtiMe',
        },
        {
            title: '类型',
            dataIndex: 'originType',
            key: 'originType',
            render: originType => <span>{originType == 1 ? '个人' : originType == 2 ? '微信群' : 'app'}</span>

        },
        {
            title: "活码使用状态",
            dataIndex: 'CodeStatus',
            key: 'CodeStatus',
            render: CodeStatus => <span style={{ width: '70px', color: CodeStatus == 1 ? '#f5222d' : '#52c41a' }}>{CodeStatus == 1 ? '结束' : CodeStatus == 2 ? '使用中' : ''}</span>
        },
        {
            title: '使用开关',
            key: 'action',
            render: (text, record) =>
                <Switch onChange={(e) => { this.handleClose(e, text) }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={text.enable == 1 ? false : true} />
        },
        // {
        //     title: '弹窗授权',

        //     key: 'isOpenModel',
        //     render: (text, record) =>
        //         <Switch onChange={(e) => { this.handleCloseModel(e, text) }} checkedChildren="开启" unCheckedChildren="关闭" defaultChecked={text.isOpenModel == 1 ? false : true} />
  
        // },
        {
            title: '发布时间',
            dataIndex: 'pushTime',
            key: 'pushTime',
            render: pushTime => <span style={{ fontSize: '13px' }}>
                {momnet(pushTime).format('YYYY-MM-DD HH:mm:ss')}
            </span>
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) =>
            (

                <Space size="middle" style={{ width: '172px' }}>
                    <NavLink to={{ pathname: '/Main/liveCodeInfo/' + text.key + '/e', }}>编辑</NavLink>
                    <div onClick={this.deleteInfoItem.bind(this, text)} style={{ color: '#1890ff' }}>删除</div>
                    {/* <Button type="danger" shape="round" size='small' ></Button> */}
                    <div onClick={this.createCodeSvg.bind(this, text.preview)} style={{ color: '#1890ff' }}>
                        生成二维码
                    </div>

                </Space>
            ),
        },
    ];
    setIsModalVisible = () => {
        this.setState({
            isModel: false
        })
    }
    CanValOpenClose = () => {
        this.setState({
            isModel: false
        })
    }
    //生成二维码
    createCodeSvg = (url = 'https://www.gaodun.com/') => {
        //
        if (url) {
            this.setState({
                isModel: true,
                CodeUrl: url
            })
        }

    }
    getUrlParams = (url, key) => {
        let search = url && url.split("?")[1];
        var theRequest = {};
        let query = search && search.split("&");
        for (let i = 0; i < query.length; i++) {
            theRequest[query[i].split("=")[0]] = decodeURI(query[i].split("=")[1]);
        }
        return key ? theRequest[key] : theRequest;
    };

    componentWillUnmount() {

        
    }
    componentDidMount() {
        let isAuthor = localStorage.getItem('isAuthor');
        //   isAuthor=0
        if (Number(isAuthor)) {
            this.setState({
                isAuthor: true
            })
        }

        get('/admin/type/list').then((res) => {
            const { data: { data } } = res;
            let isCustomer = localStorage.getItem('CustomerIds');
            if (isCustomer) {
                isCustomer = JSON.parse(isCustomer)
                let newCustomer = data.filter((v) => v.id == isCustomer[0])[0].children.filter(v3 => v3.id == isCustomer[1]);

                this.setState({
                    options: newCustomer,
                    DefaultTypeIds: isCustomer,
                    SearchTitle:{
                        ...this.state.SearchTitle,
                        originType:isCustomer[1]
                    }

                })
            } else {
                this.setState({
                    options: data
                })
            }
            this.initTable();

        }).catch((error) => {
            message.error(error?.info)
        })
        
    }
    initTable = () => {
        this.setState({
            loading: true
        })

        get('/admin/livecode/list', {
            page: this.state.pagetion.page,
            rows: this.state.pagetion.pageSize,
            status: this.state.SearchTitle.isstatus,
            title: this.state.SearchTitle.searchName,
            type: this.state.SearchTitle.type,
            typeid: this.state.SearchTitle.originType
        }).then((res) => {
            this.setState({
                loading: false
            })
            // console.log(res)
            const { data: { data: { result, totalCount } } } = res;
            this.setState({
                pagetion: { ...this.state.pagetion, total: totalCount }
            })
            const newReult = result.map((v, i) => {
                return {
                    ...v,
                    key: v.id,
                    name: v.title,
                    typename: v.typename,
                    sum: [v.totalCode, v.alreadyCode, (v.totalCode - v.alreadyCode)],
                    count: Number(v.simulation + v.alreadyNum),
                    notEwm: v.simulation,
                    pagetotal: v.alreadyNum,
                    longtiMe: v.pressNum,
                    originType: v.type,
                    CodeStatus: v.status,
                    enable: v.enable,
                    isOpenModel: v.snsapi,
                    pushTime: v.createTime * 1000
                }
            })
            this.setState({
                list: [...newReult]
            })
        }).catch((error) => {
            message.error(error?.info)
        })
    }
    deletCodeType = () => {

        // selectedRowKeys
        var _this = this;
        confirm({
            title: '确认?',
            icon: <ExclamationCircleOutlined />,
            content: '你确定要删除该活码吗?',
            onOk() {
                _this.setState({
                    loading: true
                })
                // consol
                post('/admin/livecode/editswitch', {
                    act: 'isDeleted',
                    id: _this.state.selectedRowKeys.join(','),
                    val: 1
                }).then(res => {
                    _this.setState({
                        loading: false
                    })
                    message.success('删除成功');
                    _this.setState({
                        selectedRowKeys: []
                    })
                    _this.initTable();
                }).catch((error) => {
                    message.error(error.message)
                })

            },
            onCancel() {

            },
        });

    }
    handleCurrent = (page) => {
        //重新请求api数据既可以
    }
    onSelectChange = selectedRowKeys => {

        this.setState({ selectedRowKeys });
    };

    sendSearch = () => {
        var dom = document.getElementById('SearName');
        this.setState({
            SearchTitle: {
                ...this.state.SearchTitle,
                searchName: dom.value,
                page: 1
            }
        }, () => {
            this.initTable();
        })
        //这里注意参数的获取要修改pagesize

    }
    /***
     * 根据单个Id来删除所对应的情况
     */

    handleCloseModel = (e, info) => {
        this.setState({
            loading: true
        })
        post('/admin/livecode/editswitch', {
            act: 'snsapi',
            id: info.id,
            val: e ? 2 : 1
        }).then((res) => {
            this.setState({
                loading: false
            })
            message.success(e ? '开启成功' : '关闭成功');
            this.initTable();
        }).catch((error) => {
            message.error(error)
        })
    }
    handleClose = (e, info) => {

        this.setState({
            loading: true
        })
        post('/admin/livecode/editswitch', {
            act: 'enable',
            id: info.id,
            val: e ? 2 : 1
        }).then((res) => {
            this.setState({
                loading: false
            })
            message.success(e ? '开启成功' : '关闭成功');
            this.initTable();
        }).catch((error) => {
            message.error(error)
        })
    }


    /**
     * 删除操作
     * @param {*} info 
     */
    deleteInfoItem = (info) => {
        this.setState({
            loading: true
        })
        //删除成功
        post('/admin/livecode/delete', {
            id: info.id
        }).then((res) => {
            this.setState({
                loading: false
            })
            message.success('删除成功')
            this.initTable();
            // console.log
        }).catch((error) => {
            message.error(error?.info)
        })

    }
    /**
     * 关闭
     */
    closeBtn = () => {
        post('/admin/livecode/editswitch', {
            act: 'enable',
            id: this.state.selectedRowKeys.join(','),
            val: 1
        }).then((res) => {

            message.success('关闭成功');
            this.initTable();
            this.setState({
                selectedRowKeys: []
            })
            // this.state.selectedRowKeys=[];
            this.setState({
                selectedRowKeys: []
            })
        }).catch((error) => {
            message.error(error?.info)
        })
        // try {
    }
    Copyclipboard = (content) => {
        if (copy(content)) {
            message.success('复制成功');

        } else {
            message.error('复制失败');
        }
    }

    handlePageChange = (page, pageSize) => {
        this.setState({
            pagetion: { ...this.state.pagetion, page, pageSize: pageSize }
        }, () => {

            
            // this.initTableDataTables();
            this.initTable();
        })
    }
    hanldePageSize = (pageSize) => {
        this.setState({
            pagetion: { ...this.state.pagetion, pageSize }
        }, () => {
            this.initTable();
        })
    }

    toRouter = () => {
        this.props.history.push({
            pathname: `/Main/liveCodeInfo/${this.state.SearchTitle.type}/a`,
        })
    }
    handleTree = (e, info) => {
        this.setState({
            SearchTitle: {
                ...this.state.SearchTitle,
                originType: e[e.length - 1]
            }
        })
    }
    handleTypeChange = (v, e) => {

        
        let updateInfo = {}
        if (v == 'isstatus') {
            updateInfo.isstatus = e.target.value
        } else {
            updateInfo.type = e.target.value
        }
        this.setState({
            SearchTitle: { ...this.state.SearchTitle, ...updateInfo }
        }, () => {
            this.initTable();
     
            

        })
    }
    componentWillUnmount() {
        this.setState = () => false;
    }
    render() {
        const { pagetion, pagetion: { total, page, pageSize }, selectedRowKeys, loading, size, SearchTitle, } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };

        return (
            <div className="container" style={{ overflowY: 'scroll' }}>
                 <div className="searchTop">
                    <div style={{ marginRight: '20px' }}>状态:</div>
                    <div>
               
                        <Radio.Group onChange={this.handleTypeChange.bind(this, 'isstatus')} defaultValue={0}>
                            {
                                status.map((v, i) => {
                                    return (
                                        <Radio.Button value={v.value} key={i}>{v.name}</Radio.Button>
                                    )
                                })
                            }
                        </Radio.Group>
                    </div>
                </div>
                <div className="searchCenterTitle">
                    <div>类型:</div>
                    <div>
                        <Radio.Group onChange={this.handleTypeChange.bind(this, 'type')} defaultValue={0}>
                            {types.map((v, i) => {
                                return (
                                    <Radio.Button value={v.value} key={i}>{v.name}</Radio.Button>
                                )
                            })}

                        </Radio.Group>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div>名称</div>
                        <div style={{ display: 'flex' }}>
                            <Input placeholder="" style={{ width: '240px', marginRight: '40px' }} id="SearName" />
                            <div style={{ display: 'flex' }}>
                                <Button type="primary" style={{ marginRight: '20px' }} onClick={this.sendSearch}>查询</Button>
                                <Button>清除</Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="searchTypes">
                    <div>
                        分类:
                    </div>
                    <div>

                        <Cascader
                            defaultValue={this.state.DefaultTypeIds}
                            changeOnSelect
                            options={this.state.options} placeholder="全部" onChange={this.handleTree}
                            fieldNames={{ label: 'typeName', value: 'id', children: 'children' }} />

                    </div>
                </div>
                <div className="searchTypes">
                    <div style={{ display: 'flex' }}>
                        <Button type="primary" style={{ marginRight: '20px' }} onClick={this.toRouter}>+新建</Button>

                        <Button style={{ marginRight: '20px' }} onClick={this.closeBtn}>关闭</Button>

                        {this.state.isAuthor ? <Button onClick={this.deletCodeType}>删除</Button> : ''}
                    </div>
                </div>
                <div style={{ display: selectedRowKeys.length > 0 ? 'block' : 'none' }}>
                    <div style={{ margin: '20px', padding: '13px', backgroundColor: 'rgba(186, 231, 255, 1)', borderRadius: '5px', display: 'flex', alignItems: 'center' }}>
                        <InfoCircleOutlined style={{ color: '#0E77D1', marginRight: '20px', fontSize: '16px', overflow: 'hidden' }} /><span>已选择{selectedRowKeys.length}项</span>
                    </div>
                </div>
                <div style={{ margin: '20px' }}>
                    <Table rowSelection={rowSelection} rowKey={record => record.id} loading={loading} onChange={this.handleCurrent} pagination={false} bordered columns={this.columns} dataSource={this.state.list}></Table>
                    <div style={{ position: 'relative', display: 'flex', justifyContent: 'space-between', marginTop: '20px' }} >
                        <div style={{ display: 'flex', alignItems: 'center' }}>共{total}条记录<span>第{page}/{total % pageSize == 0 ? total / pageSize : parseInt((total / pageSize + 1))}</span></div>
                        <Pagination
                            total={total}
                            showSizeChanger
                            showQuickJumper
                            current={page}
                            onChange={this.handlePageChange}
                        />
                    </div>
                </div>
                <ModelInfo 
                   isEnable={this.state.isModel} 
                   title={''} width={799}
                   isShowFoot={null}
                   setIsModalVisible={this.setIsModalVisible}
                   CanValOpenClose={this.CanValOpenClose}
                  >
                    <div style={{ marginBottom: '20px', display: 'flex' }}>
                        <QRCode value={this.state.CodeUrl} renderAs='canvas' level='H' />,
                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '51px', marginTop: '30px' }}>链接地址:<span style={{ color: "#409eff" }}>{this.state.CodeUrl}</span><Button style={{ width: '92px' }} type="primary" onClick={this.Copyclipboard.bind(this, this.state.CodeUrl)}>复制链接</Button></div>

                    </div>
                </ModelInfo> 
            </div>
        )
    }
}

export default withRouter(Index);