/*
 * @Author: your name
 * @Date: 2021-06-03 11:48:59
 * @LastEditTime: 2021-08-11 16:12:05
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \livecode\src\store\reducer\global\login.js
 */
import { lazy } from "react";
import { INIT_LEFTMENU, INIT_MENUROUTER } from "../../actionTypes/index";
const defaultStatus = {
  isLeft: false,
  leftMenuRouters: [
    {
      path: "/Main/LoginSuccess",
      component: "LiveCodeModules/Containers/LoginSuccess",
    },
    {
      path: "/Main/LiveCodeSetting",
      component: "LiveCodeModules/Containers/LiveCodeSetting",
    },
    {
      path: "/Main/LiverCodeList",
      component: "LiveCodeModules/Containers/LiveCodeList",
    },
    {
      path: "/Main/liverCodeCard",
      component: "LiveCodeModules/Containers/LiverCard",
    },
    {
      path: "/Main/liveCodeDetail",
      component: "LiveCodeModules/Containers/LiveCodeDetail",
    },
    {
      path: "/Main/liveCodeInfo",
      component: "LiveCodeModules/Containers/LiveCodeDetail/LiveCodePersonal",
    },
    //召回模块代码
    {
      path: "/Main/recall/manages", //项目管理页面
      component: "LiveCodeModules/Containers/recall/views",
    },
    {
      path: "/Main/recall/statistics",
      component: "LiveCodeModules/Containers/recall/views/statistics",
    },
    {
      path: "/Main/recall/notetemplate",
      component: "LiveCodeModules/Containers/recall/views/notetemplate",
    },
    {
      path: "/Main/recall/noteTask",
      component: "LiveCodeModules/Containers/recall/views/noteTask",
    },
    {
      path: "/Main/recall/noteData",
      component: "LiveCodeModules/Containers/recall/views/noteData",
    },
    {
        path:"/Main/recall/taskInfo/:id",
        component: "LiveCodeModules/Containers/recall/views/taskInfo",
    },{
      path:'/Main/recall/behaviour',
      component: "LiveCodeModules/Containers/recall/views/behaviour",
    },{
      path:'/Main/recall/behaviourDetail/:id',
      component: "LiveCodeModules/Containers/recall/views/behaviorIDetail",
    }
  ], //左侧路由也可以称之为权限
};

export default (preState = defaultStatus, { type, value }) => {
  switch (type) {
    case INIT_LEFTMENU:
      return { ...preState, isLeft: value };
    case INIT_MENUROUTER:
      return { ...preState, leftMenuRouters: value };
    default:
      return { ...preState };
  }
};
