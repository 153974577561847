import React, { memo, useState, useEffect, createContext } from 'react'
import { Switch, Form, Input, TreeSelect, Checkbox, Modal, Button, Space, message, Spin, Drawer, Transfer, Tree } from 'antd';
import './index.css'
const labelChildrenDataSource=(arr,parent)=>{
    const newMap=arr.map((v2,index)=>{
        return {
            key: !parent ? `${v2.id}` : `${parent.id}-${v2.id}`,
            title: v2.typeName,
            children:v2.children&&v2.children?labelChildrenDataSource(v2.children,v2):[]
        }
    })
    return newMap
}
export   const handleIds = (list, id, type='array',label,keys) => {
    return type == 'array' ?handleUpdateArray(list, id,label,keys):handleUpdateString(list, id)
}
export  const handleUpdateArray = (list, id,label,keys) => {
    var result=[];
    var labels=label.split(',')
    labels.forEach((v) => {
        result=[...result,getParentsById(list, +v,keys)?.map(v => +v.id).reverse().join('-')]
    })
    return result;
}
const handleUpdateString = (list, id) => {
    return getParentsById(list, id,)?.map(v => v.id).reverse().join('-')
}

export   const getParentsById = (list, id,key='id') => {
    for (let i in list) {
        if (list[i][key] === id) {
            //查询到就返回该数组对象
            return [list[i]]
        }
        if (list[i].children) {
            let node = getParentsById(list[i].children, id,key)
            if (node !== undefined) {
                //查询到把父节点连起来
                return node.concat(list[i])
            }
        }
    }
}
function Index(props) {
    //回显数据
    useEffect(()=>{
        if (props.isEditorEnable) {
            let result = handleGetUpdateKeys(props.letLabelData, props.selectItems.labelTypeId)
            setTargetKeys([...result])
        }else{
            setTargetKeys([])
        }

    },[props.isVisble])
    
    const handleGetUpdateKeys = (list, labelKeys) => {
      return  handleIds(list,1,'array',labelKeys)
    }
    const isChecked = (selectedKeys, eventKey) => selectedKeys.indexOf(eventKey) !== -1;
    const generateTree = (treeNodes = [], checkedKeys = []) =>
        treeNodes.map(({ children, ...props }) => ({
            ...props,
            disabled: checkedKeys.includes(props.key),
            children: generateTree(children, checkedKeys),
        }));
    const TreeTransfer = ({ dataSource, targetKeys, ...restProps }) => {
        const transferDataSource = [];
        function flatten(list = []) {
            list.forEach(item => {
                transferDataSource.push(item);
                flatten(item.children);
            });
        }
        flatten(dataSource.length?dataSource:[]);
        return (
            <Transfer
                {...restProps}
                targetKeys={targetKeys}
                dataSource={transferDataSource}
                className="tree-transfer"
                render={item => item.title}
                oneWay={false}
                showSelectAll={true}
            >
                {({ direction, onItemSelect, selectedKeys }) => {
                    if (direction === 'left') {
                        const checkedKeys = [...selectedKeys, ...targetKeys];
                        return (
                            <Tree
                                blockNode
                                checkable
                                checkedKeys={checkedKeys}
                                defaultExpandAll
                                treeData={generateTree(dataSource?dataSource:[], targetKeys)}
                                onCheck={(_, { checkedNodes, node: { key }, halfCheckedKeys }) => {

                                    setTargetKeys((preState) => {
                                        console.log(Array.from(new Set([...preState, ..._])))
                                        return Array.from(new Set([...preState, ..._]))
                                    })

                                    onItemSelect(key, !isChecked(checkedKeys, key));
                                }}
                                onSelect={(_, { selectedNodes, node: { key } }) => {
                                    console.log(selectedNodes, _)
                                    onItemSelect(key, !isChecked(checkedKeys, key));
                                }}
                            />
                        );
                    }
                }}
            </Transfer>
        );
    }

    
    
   

  
    const [targetKeys, setTargetKeys] = useState([]);
    const onChange = keys => {
        setTargetKeys(keys);
    };
    const handleSubmit = () => {
        props.handleSubmit(targetKeys)
    
    }
    const handleReset = () => {props.handleReset(false); setTargetKeys([])
    }
    const TreeDataSource=labelChildrenDataSource(props.letLabelData)

    return (
        <div >
            <Drawer placement={'left'} title='设置标签'
                width={'800'}
                className="leftDrawer"
                key={'left'}
                onClose={handleReset}
                visible={props.isVisble}>
                <div className="leftContent">
                    <TreeTransfer dataSource={TreeDataSource?TreeDataSource:[]} targetKeys={targetKeys} onChange={onChange} />
                </div>
                <div style={{ margin: '20px', textAlign: 'end' }}>
                    <Button style={{ marginRight: "20px" }} onClick={handleReset}>取消</Button>
                    <Button type="primary" onClick={handleSubmit}>确认</Button>
                </div>
            </Drawer>
        </div>
    )
}
export default memo(Index)